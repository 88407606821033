import React, { useEffect } from "react";
import SCMHeader from "../Reusables/Header/SCMHeader";
import Footer from "../Reusables/Footer/Footer";
import About from "./components/About";
import Stor from "./components/Stor";
import Approac from "./components/Approac";
import Opportunity from "./components/Opportunity";
const AboutScm = () => {
  useEffect(() => {
    document.title = "About SCM";
  }, []);
  return (
    <div>
      <SCMHeader />
      <About />
      <Stor />
      <Approac />
      <Opportunity />
      <Footer />
    </div>
  );
};

export default AboutScm;
