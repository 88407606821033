import React,{useEffect} from 'react'
import Asset from './components/Asset'
import SCMHeader from '../Reusables/Header/SCMHeader'
import Footer from '../Reusables/Footer/Footer'
const AssetBackedNote = () => {
  useEffect(() => {
    document.title = "Asset Backed Note";
  }, []);
  return (
    <div>
        <SCMHeader/>
        <Asset/>
        <Footer/>
        </div>
  )
}

export default AssetBackedNote