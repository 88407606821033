import React from "react";
import "./index.scss";
import Building from "../../assets/Rectangle 21 (1).png";
import Scroll from "../../Reusables/Animation/Scroll";
import Logo from "../../assets/logo.jpeg";
import Scm from "../../assets/img/scm2.jpeg";
const About = () => {
  // const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div className="overflow-hidden">
      <div className="relative">
        <div className=" h-[60vh] relative top-[65px] sm:h-screen w-full  bg-[black]">
          <img
            src={Scm}
            alt=""
            className=" h-full opacity-70 w-full  object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Sthenic Capital Management Limited
            </h2>
          </div>
        </div>
      </div>

      <div className="bg-[#5DDDFF]">
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] scm-cover-main px-[2em] sm:px-[4em] w-full overflow-hidden py-[60px]">
          <div className="scm-cover-title">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px] text-center">
              Overview
            </h2>
          </div>

          <Scroll animationDirection={"left"} possibleClass="pb-[3em]">
            <h2 className="text-[35px] leading-[36px] text-[#001D45]">
              Who We Are
            </h2>
            <p className=" text-[black] text-[16px] mb-[10px] text-justify">
              Sthenic Capital Management Limited (SCM) is Sthenic’s investment
              management and investing business of the Group. It is licensed by
              the Securities and Exchange Commission (SEC) as Fund Manager. SCM
              specialises in structured private debt fund management.
            </p>
            <p className=" text-[black] text-[16px] mb-[10px] text-justify">
              SCM is your go-to partner for tailored solutions such as
              financing, investing, and trading. We are experts in providing
              non-traditional financing choices and alternative loans to
              mid-market and growing businesses via smart debt. Our distinct
              strategy entails long-term collaboration with high-margin firms.
              We channel finances to individual needs by utilizing smart money
              on a short-term rolling model, resulting in a greater return that
              facilitates growth.
            </p>
            <p className=" text-[black] text-[16px] mb-[10px] text-justify">
              At Sthenic, we are more than just investment managers. Our robust
              consulting services and wide network of partners in our core areas
              of interests and related ventures make us distinct. Our team
              comprises investment managers with systematic training and
              culture, making us deliver only the best results to our clients.
              With our expertise and commitment, we can help you navigate the
              complex web of investment and guide you to achieve your financial
              goals.
            </p>
          </Scroll>
        </div>
      </div>
      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] scm-cover-main px-[2em] sm:px-[4em]">
        <div className="p-flex flex flex-col-reverse sm:flex-row justify-between my-[70px] mx-0">
          <Scroll
            animationDirection={"left"}
            possibleClass="p-flex-item w-full sm:w-[45%]"
          >
            <h4 className=" text-[28px] leading-[36px] text-[#001D45] mb-2">
              Pedigree
            </h4>
            <ul className="">
              <li className="flex items-start my-1">
                <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />

                <p className="text-justify">
                  With over two decades of combined expertise in corporate and
                  investment banking, private equity investment and investment
                  management, debt, structured finance and middle market
                  origination and financing, our fund managers are known in
                  their field.
                </p>
              </li>
              <li className="flex items-start my-1">
                <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />

                <p className="text-justify">
                  We have successfully made investments by utilising our unique
                  Private Debt Fund strategy in more than 30 exceptional
                  businesses and opportunity-seeking companies.
                </p>
              </li>
            </ul>
          </Scroll>

          <Scroll
            animationDirection={"right"}
            possibleClass="p-flex-item w-full sm:w-[45%] mb-[50px] sm:mb-0"
          >
            <div className="w-full">
              {" "}
              <h2 className="text-[28px] leading-[36px] text-[#001D45]">
                Our Strategy
              </h2>
              <div>
                <p className="text-[16px] mb-[10px] text-justify">
                  The cornerstone of our strategy include execution strength,
                  agility, and speed. Our platform helps us achieve this by
                  bringing together established investors looking for alphas and
                  growth firms looking for investments that unlock high returns
                  and build entrenched values.
                </p>
              </div>
            </div>
          </Scroll>
        </div>
      </div>
    </div>
  );
};

export default About;
