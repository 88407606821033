import React,{useEffect} from "react";
import SCMHeader from "../Reusables/Header/SCMHeader";
import WorkCapital from "./components/WorkCapital";
import Footer from "../Reusables/Footer/Footer";
const WorkingCapital = () => {
  useEffect(() => {
    document.title = "Working Capital";
  }, []);
  return (
    <div>
      <SCMHeader />
      <WorkCapital />
      <Footer />
    </div>
  );
};

export default WorkingCapital;
