import React from "react";
import Linkedin from "../../assets/linkedin.png";
import "./Footer.scss";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="">
      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px]">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
          <div className="footer-flex-item">
            <div className=" mb-[1.5em]">
              <h4>Sthenic Capital Management</h4>
              <p>investmentinfo@sthenicfinance.com</p>
            </div>

            <div className="">
              <h4>Sthenic Finance Advisory</h4>
              <p>advisoryinfo@sthenicfinance.com</p>
            </div>
          </div>

          <div className="">
            <div className=" mb-[1.5em]">
              <h4>Address</h4>
              <p>112A, Association Road, Dolphin Estate, Ikoyi, Lagos.</p>
            </div>

            <div className="">
              <h4>Customer Service</h4>
              <p>02016330151</p>
            </div>
          </div>

          <div className="w-[25%] sm:w-[10%] mt-[30px]">
            <ul className="flex gap-4 flex-col md:flex-row">
              <li className="md:mb-[1.5em]">
                <Link
                  to={
                    "https://ng.linkedin.com/company/sthenic-finance-advisory"
                  }
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={Linkedin} alt="" className="w-[30px] h-[30px]" />
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="text-center mt-[45px]">
          <p>
            Copyright {new Date().getFullYear()} by Sthenic Finance and Advisory
            Limited. All Right Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
