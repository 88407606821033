import React from "react";
import Tick from "../../assets/tick.svg";
import Vector from "../../assets/Vector.svg";
import { Link } from "react-router-dom";
import Scroll from "../../Reusables/Animation/Scroll";
import Contac from "../../assets/img/contact.jpeg";

const Contact2 = () => {
  // const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className="h-[60vh] relative top-[65px] sm:h-screen w-full  bg-[black]">
          <img
            src={Contac}
            alt=""
            className=" h-full opacity-70 w-full  object-cover object-center"
          />
        </div>

        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Contact Us
            </h2>
            <p className="text-[16px] sm:text-[18px] text-justify">
              We're delighted that you're interested in getting in touch with
              us! If you have any questions, feedback, or inquiries, please feel
              free to reach out to us using any of the methods below. Our
              dedicated team is always ready to assist you in any way we can.
            </p>
          </div>
        </div>
      </div>

      <div className=" flex justify-center overflow-hidden">
        <Scroll
          animationDirection={"right"}
          possibleClass="contact-container  px-[2em] sm:px-[4em] py-7em] md:px-[12em] h-[100vh] flex flex-col justify-center"
        >
          <div className="contact-q-container bg-[#001D45] py-[3em] px-[1em] sm:px-[4em] md:px-[2em] text-[#fff]">
            <h3 className="question text-[20px] leading-[28px] mb-[1em]">
              2. Great! We would love to talk to you about how our advice can
              help you. Select the area you want to talk about below:
            </h3>

            <div className="options flex flex-col w-[70%] gap-[1em] ml-[1.5em]">
              <button className="answer hover:bg-[#0177b233] flex items-center py-[0.3em] px-[0.5em] text-left border-2 border-solid border-[#808080] rounded-[6px] bg-transparent transition-all duration-400 ease-in-out">
                <span className="label bg-[white] w-[35px] h-[35px] rounded-[50%] flex justify-center items-center mr-[1em] text-[14px] leading-[24px] text-[#001D45]">
                  A
                </span>

                <span className="answer-text text-[white] mr-[2em] text-[18px] leading-[24px]">
                  Investment management and financial planning
                </span>
                <span className="tick hidden">
                  <img src={Tick} alt="" />
                </span>
              </button>

              <button className="answer hover:bg-[#0177b233] flex items-center py-[0.3em] px-[0.5em] text-left border-2 border-solid border-[#808080] rounded-[6px] bg-transparent transition-all duration-400 ease-in-out">
                <span className="label bg-[white] w-[35px] h-[35px] rounded-[50%] flex justify-center items-center mr-[1em] text-[14px] leading-[24px] text-[#001D45]">
                  B
                </span>
                <span className="answer-text text-[white] mr-[2em] text-[18px] leading-[24px]">
                  Financial advisory services
                </span>
                <span className="tick hidden">
                  <img src={Tick} alt="" />
                </span>
              </button>
            </div>

            <Link to="/contact2">
              <button className="hover:bg-transparent hover:border-2 hover:border-solid hover:border-[#5DDDFF] contact-btn transition-all duration-300 ease-in-out border-none text-[#fff] ml-[1em] flex items-center  py-[15px] px-[30px] text-[24px] px-[2em] py-1em] h-[52px] leading-[32px] mt-[1.5em] bg-[#5DDDFF] flex items-center">
                <span>Submit</span>
                <span>
                  <img className="btn-img ml-[5px]" src={Vector} alt="" />
                </span>
              </button>
            </Link>
          </div>
        </Scroll>
      </div>
    </div>
  );
};

export default Contact2;
