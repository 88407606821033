import React, {useEffect} from "react";
import SFAHeader from "../Reusables/Header/SFAHeader";
import Footer from "../Reusables/Footer/Footer";
import Capability from "./components/Capability";
const Capabilities = () => {
  useEffect(() => {
    document.title = "Our Capabilities";
  }, []);
  return (
    <div>
      <SFAHeader />
      <Capability />
      <Footer />
    </div>
  );
};

export default Capabilities;
