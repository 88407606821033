import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Scroll from "./Animation/Scroll";

import Vector from "../assets/Vector.svg";

function ContactForm() {
  const [state, handleSubmit] = useForm("xgejgzyn");
  // const test = JSON.parse(localStorage.getItem("imgs"));
  if (state.succeeded) {
    return (
      <div className="md:w-1/2 bg-[#001D45] h-[50vh] my-10 mx-auto mt-[100px] text-white text-center flex items-center justify-center md:text-5xl flex-col rounded-md">
        <p>Thanks for Contacting us!</p>
        <p>We will get back to you soon.!</p>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className=" flex justify-center overflow-hidden">
        <div className="contact-container px-0 sm:px-[4em] py-[5em]  flex flex-col justify-center w-[70%]">
          <Scroll
            animationDirection={"right"}
            possibleClass="contact-q-container bg-[#001D45] py-[3em] px-[1em] sm:px-[4em] md:px-[2em] text-[#fff]"
          >
            <div className="options flex flex-col w-full gap-[1em] ">
              <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
                <label
                  className="text-[16px] leading-[24px] text-[white]"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className="duration-300 transition-all ease-in-out text-[white] rounded-[2px] bg-transparent outline-none border-2 border-solid border-[#808080] p-[0.7em]"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter name"
                />
                <ValidationError
                  prefix="Name"
                  field="name"
                  errors={state.errors}
                />
              </div>

              <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
                <label
                  className="text-[16px] leading-[24px] text-[white]"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="duration-300 text-[white] transition-all ease-in-out  rounded-[2px] outline-none border-2 border-solid border-[#808080] p-[0.7em] bg-transparent"
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Enter your Email"
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>

              <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
                <label
                  className="text-[16px] leading-[24px] text-[white]"
                  htmlFor="purpose"
                >
                  Purpose
                </label>
                <input
                  className="duration-300 text-[white] transition-all ease-in-out  rounded-[2px] outline-none border-2 border-solid border-[#808080] p-[0.7em] bg-transparent "
                  type="text"
                  id="purpose"
                  name="purpose"
                  placeholder="Purpose"
                />
                <ValidationError
                  prefix="Purpose"
                  field="purpose"
                  errors={state.errors}
                />
              </div>
              <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
                <label
                  className="text-[16px] leading-[24px] text-[white]"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  cols="30"
                  rows="10"
                  className="bg-transparent border-2 border-solid border-[#808080]"
                ></textarea>
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </div>
            </div>
            <button
              type="submit"
              disabled={state.submitting}
              className="hover:bg-transparent hover:border-2 hover:border-solid hover:border-[#5DDDFF] contact-btn transition-all duration-300 ease-in-out border-none text-[#fff] ml-[1em] flex items-center  py-[15px] px-[30px] text-[24px] px-[2em] py-[1em] h-[52px] leading-[32px] mt-[1.5em] bg-[#5DDDFF] flex items-center"
            >
              <span>Submit</span>
              <span>
                <img className="btn-img ml-[5px]" src={Vector} alt="" />
              </span>
            </button>
          </Scroll>
        </div>
      </div>
    </form>
  );
}

export default ContactForm;
