import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/logo.jpeg";
import useWindowSize from "../../hooks/useWindowSize";
const SCMHeader = () => {
  const [view, setView] = useState(false);
  const [drop, setDrop] = useState(0);
  const location = useLocation();
  const { width } = useWindowSize();

  return (
    <div className="flex justify-center h-[68px] bg-[#001D45] w-full fixed z-[1] ">
      <header className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] flex justify-between items-center h-[68px] w-full bg-[#001D45] text-[white] px-[2em] sm:px-[4em] py-0 fixed z-[1]">
        <div className="relative sm:top-[25px]  ">
          <Link to="/">
            <img
              src={Logo}
              alt="logo"
              className=" h-[50px] sm:w-[125px] z-[-40] bg-[#001D45] rounded-[50%] sm:h-[125px]"
            />
          </Link>
        </div>

        <nav
          className={` ${
            view && width < 641
              ? "block w-[80%]  h-[75vh] transform translate-y-[60%]  transition-all duration-1000 ease-in-out absolute py-[20px] "
              : " sm:block transform h-full translate-y-[-1000%] sm:translate-y-0 w-full  transition-all duration-1000 ease-in-out"
          }  sm:w-[80%] md:w-[70%]  lg:w-[60%] bg-[#001D45]
        }`}
        >
          <ul
            className={`flex flex-col sm:flex-row justify-between items-center h-full`}
          >
            <div
              className={`text-[12px] py-[10px] w-full flex justify-end sm:justify-center relative md:text-[14px] ${
                drop === 2 ? "h-[30px] mb-[10em] sm:h-full sm:mb-0" : "h-full"
              } text-right sm:text-left  px-[1em] hover:bg-[#5DDDFF] hover:text-[black]  cursor-pointer`}
            >
              <Link to={"/scm/about-us"}>
                <li className="h-full flex items-center justify-end ">
                  About Us
                </li>
              </Link>
            </div>
            <div
              className={`text-[12px] py-[10px] w-full flex justify-end sm:justify-center relative md:text-[14px] ${
                drop === 2 ? "h-[30px] mb-[10em] sm:h-full sm:mb-0" : "h-full"
              } text-right sm:text-left  px-[1em] hover:bg-[#5DDDFF] hover:text-[black]  cursor-pointer`}
            >
              <Link to={"/scm/funds"}>
                <li className="h-full flex items-center justify-end">
                  Our Funds
                </li>
              </Link>
            </div>
            <Link
              className={`text-[12px] py-[10px] md:text-[14px] w-full sm:auto h-full flex justify-end sm:justify-center items-center px-[1em] hover:bg-[#5DDDFF] hover:text-[black] ${
                location.pathname === "/scm/products"
                  ? "bg-[#5DDDFF] text-[black]"
                  : ""
              } `}
              to="/scm/products"
            >
              <li className="h-full flex items-center justify-end">Products</li>
            </Link>
            <Link
              className={`text-[12px] py-[10px] md:text-[14px] w-full sm:auto h-full flex justify-end sm:justify-center items-center px-[1em] hover:bg-[#5DDDFF] hover:text-[black] ${
                location.pathname === "/scm/our-impact"
                  ? "bg-[#5DDDFF] text-[black]"
                  : ""
              } `}
              to="/scm/our-impact"
            >
              <li className="h-full flex items-center justify-end">
                Our Impact
              </li>
            </Link>
            <div
              onMouseEnter={() => setDrop(3)}
              className={`text-[12px] py-[10px] w-full sm:auto relative md:text-[14px] flex justify-end sm:justify-center ${
                drop === 3 ? "h-[30px] mb-[10em] sm:h-full sm:mb-0" : "h-full"
              } text-right sm:text-left    px-[1em] hover:bg-[#5DDDFF] hover:text-[black]  cursor-pointer`}
            >
              <Link to={"/scm/investor"}>
                <li className="h-full flex items-center justify-end">
                  Investors
                </li>
              </Link>
              <ul
                onMouseLeave={() => setDrop(0)}
                className={`${
                  drop === 3 ? "flex" : "hidden"
                }  flex-col relative top-0 left-0 sm:top-[68px] sm:left-[10%] sm:absolute bg-transparent sm:bg-[#001D45] w-full sm:w-[200px] my-[20px] sm:my-0 transition-all duration-400 ease-in-out`}
              >
                <Link
                  to={"/scm/investor"}
                  className="px-[10px] text-[white] hover:text-[black] py-[5px] sm:py-[20px] hover:bg-[#5DDDFF] cursor-pointer"
                >
                  <li className="text-right sm:text-left"> Investors</li>
                  <p className="text-right sm:text-left">
                    I have capital to invest
                  </p>
                </Link>
                <Link
                  to={"/scm/investee"}
                  className="px-[10px] text-[white] hover:text-[black] py-[5px] sm:py-[20px] hover:bg-[#5DDDFF] cursor-pointer"
                >
                  <li className="text-right sm:text-left">Investees</li>
                  <p className="text-right sm:text-left">
                    I need capital investment for my business
                  </p>
                </Link>
              </ul>
            </div>
            <Link
              className={`text-[12px] py-[10px] md:text-[14px]  w-full sm:auto h-full flex justify-end sm:justify-center items-center px-[1em] hover:bg-[#5DDDFF] hover:text-[black] ${
                location.pathname === "/scm/careers"
                  ? "bg-[#5DDDFF] text-[black]"
                  : ""
              } `}
              to="/scm/careers"
            >
              <li className="h-full flex items-center justify-end">Careers</li>
            </Link>
            <Link
              className={`text-[12px] py-[10px] sm:py-0 md:text-[14px] w-full sm:auto  h-full flex justify-end sm:justify-center items-center px-[1em] hover:bg-[#5DDDFF] hover:text-[black] ${
                location.pathname === "/scm/contact-us"
                  ? "bg-[#5DDDFF] text-[black]"
                  : ""
              } `}
              to="/scm/contact-us"
            >
              <li className="h-full flex items-center justify-end">
                Contact Us
              </li>
            </Link>
          </ul>
        </nav>

        <div
          className=" block sm:hidden none cursor-pointer"
          onClick={() => setView((prev) => !prev)}
        >
          <div
            className={`${
              view
                ? "transform translate-y-[5px] rotate-[45deg] transition-all duration-900 ease-in-out"
                : ""
            }  h-[4px] bg-[white] rounded-[4px] w-[30px] mb-[6px]`}
          ></div>
          <div
            className={`  ${
              view
                ? "fixed left-[-100%] transition-all duration-600 ease-in-out"
                : ""
            } bar h-[4px] bg-[white] rounded-[4px] w-[30px] mb-[6px]`}
          ></div>
          <div
            className={`${
              view
                ? "transform translate-y-[-5px] rotate-[-45deg] transition-all duration-900 ease-in-out"
                : ""
            } h-[4px] bg-[white] rounded-[4px] w-[30px] mb-[6px]`}
          ></div>
        </div>
      </header>
    </div>
  );
};

export default SCMHeader;
