import React,{useEffect} from 'react'
import SCMHeader from "../Reusables/Header/SCMHeader"
import Portfolio from './components/Portfolio'
import Footer from "../Reusables/Footer/Footer"
const Portfolios = () => {
  useEffect(() => {
    document.title = "Portfolios";
  }, []);
  return (
    <div>
        <SCMHeader/>
        <Portfolio/>
        <Footer/>
        </div>
  )
}

export default Portfolios