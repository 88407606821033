import React from "react";
import Scroll from "../../Reusables/Animation/Scroll";
import Logo from "../../assets/logo.jpeg";
import "./index.scss";
import Sfa from "../../assets/img/sfa.jpeg";
const ABout = () => {
  // const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className=" h-[60vh] relative top-[65px] sm:h-screen w-full bg-[black]">
          <img
            src={Sfa}
            alt=""
            className=" h-full opacity-70 w-full object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Sthenic Finance and Advisory Limited
            </h2>
          </div>
        </div>
      </div>

      <div className="overflow-hidden py-[60px]">
        <div className="about-us-title ">
          <h2 className="text-[25px] md:text-[35px] lg:text-[60px] text-center">
            Overview
          </h2>
        </div>
        <div className="bg-[#5DDDFF]">
          <div className=" mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] sm:px-[4em] py-[2em]">
            <Scroll animationDirection={"left"} possibleClass="sect">
              <h4 className="text-[28px] leading-[36px] text-[#001D45] ">
                Who We Are
              </h4>
              <p className="text-[16px] leading-[28px] text-[black] mb-[15px] ">
                We are Sthenic Finance and Advisory Limited, a financial
                advisory firm in Nigeria with with an African outlook.
              </p>
              <p className="text-[16px] leading-[28px] text-[black] mb-[15px]">
                Since we commenced operation in 2019 as an. Investment bank, we
                have evolved into a diligent provider of professional business
                and financial advisory services in Nigeria. Our services include
                specialised finance, financial advisory, public sector advisory,
                mergers and acquisition, equity capital raise, structured
                finance and arrangement, and infrastructure project financing.
              </p>
              <p className="text-[16px] leading-[28px] text-[black] mb-[15px]">
                What sets us apart from other investment banking firms is our
                depth of local knowledge, connected network, and deployment of
                value adds on both the buy and sell sides. This enables us to
                support our clients throughout the lifecycle of their
                transactions for efficient outcomes. This we have proven
                multiple times.
              </p>
              <p className="text-[16px] leading-[28px] text-[black] mb-[15px]">
                At Sthenic, we pride ourselves on the expertise of our leading
                professionals who have a wealth of experience across investment
                banking, corporate banking, business structure advisory,
                transaction and management, and fund raising in the capital
                markets. Supported by a broad range of investors base, we are
                able to produce result on what is ordinarily daunting financing
                tasks.
              </p>
              <p className="text-[16px] leading-[28px] text-[black] mb-[15px]">
                Our track records of success in financial services, technology
                and telecommunication, agriculture, manufacturing, retail
                services, fintech, logistics and several other sectors, using
                our financing product development savviness besides a broad
                based investors network speak to our evolving position in the
                market.
              </p>
            </Scroll>
          </div>
        </div>
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] sm:px-[4em]">
          <div className="p-flex flex flex-col sm:flex-row justify-between items-start my-[70px] mx-0">
            <Scroll
              animationDirection={"left"}
              possibleClass="p-flex-item w-full sm:w-[45%]"
            >
              <h4 className="text-[28px] leading-[36px] text-[#001D45]">
                Pedigree
              </h4>
              <ul className="">
                <li className="flex items-start my-[10px]">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />

                  <p>
                    Proven depth of experience and expertise in debt and equity
                    capital raise from both the local and foreign markets
                  </p>
                </li>
                <li className="flex items-start my-[10px]">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />

                  <p>
                    Over ten years of multi assets and multi-sector research
                    capabilities and deep dive involvement in successful
                    transaction advisory and investment management.
                  </p>
                </li>
                <li className="flex items-start my-[10px]">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />

                  <p>
                    Solid track record in Mergers and Acquisitions (M&A) and
                    Leveraged Buy Outs (LBO) in the local market.
                  </p>
                </li>
                <li className="flex items-start my-[10px]">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />

                  <p>
                    Duly licensed as Fund Manager by the Securities and Exchange
                    Commission (SEC) in Nigeria.
                  </p>
                </li>
              </ul>
            </Scroll>

            <Scroll
              animationDirection={"right"}
              possibleClass="p-flex-item w-full sm:w-[45%] mt-[30px] sm:mt-0"
            >
              <h4 className="text-[28px] leading-[36px] text-[#001D45]">
                Our Strategy
              </h4>
              <p>
                We stay impactful using the unique “Sthenic Approach”. This
                step-by-step “in-you-shoes” technique focuses on identifying
                value for money regardless of the deal’s profile or type. We pay
                specific attention to deal design and structure to meet the
                appetite of the market and our investing or community. Our focus
                on increasing the chances of successful outcomes be it capital
                raise, investing or advisory, forms a large part of our 80-20
                rules of engagement.
              </p>
            </Scroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ABout;
