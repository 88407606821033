import React from "react";
import Scroll from "../../Reusables/Animation/Scroll";
import "./index.scss";
const Asset = () => {
  const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className="  h-[60vh] relative top-[65px] sm:h-screen w-full  bg-[black]">
          <img
            src={test.Farm}
            alt=""
            className=" h-full opacity-70 w-full  object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Asset Backed Note
            </h2>
          </div>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] career-container py-[2em] px-[2em] sm:px-[4em] overflow-hidden">
        <Scroll
          animationDirection={"left"}
          possibleClass="career-header text-[#001D45] my-[3em]"
        >
          <h2 className="text-[40px] leading-[48px] ">Asset Backed Note</h2>
          <p className="text-[16px] leading-[24px] mb-[15px]">
            This debt note is extended for the purpose of providing support in
            the expansion of business operations, through the acquisition of
            assets. Structuring of this credit, is a function of the cashflow of
            the assets, and associated risk and is available to businesses
            seeking to finance cashflow. generating or receivable backed asset,
            at low covenants.
          </p>
          <p>
            1.Takes the characteristics of a structured lease. This includes
            financing of operational assets of a business, for a weekly lease
            rental income of both principal and interest. The asset is co-owned
            by both parties and can be repurchased by the client, at final
            payment of facility.
          </p>
          <p>
            {" "}
            2. A standard asset buy-back arrangement, where facility amount is
            ringfenced by the financed assets and the asset is purchased in the
            Investor’s Name. This is structured on a take or pay payment
            arrangement.
          </p>
        </Scroll>
        <Scroll
          animationDirection={"right"}
          possibleClass="product-main relative w-full h-full"
        >
          <table className="w-full border-collapse">
            <tr>
              <th>SECTOR</th>
              <th>Agnostic B2B; B2C</th>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                CLUSTER OF INTEREST
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                Haulage Trucking, Equipment Manufacturing, Food & Beverage
                Manufacturing, Restaurants,Energy Bar & Restaurants, Corporate
                Logistics
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                TRANSACTION SIZE
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                Asset value: c 3-20m Naira
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                PRICING
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                Multiple of asset value - Minimum of 1.5x
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                TENOR
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                6-24 months
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                PROCESSING TIME
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                7-14 days
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                REPAYMENT SOURCE
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                Revenue generated from financed asset
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                REQUIREMENTS
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                <li>Equity Contribution up to 20% minimum</li>
                <li>Take or pay agreement of the asset from the business</li>
                <li>Self collate ralization of asset</li>
                <li>Enhancement</li>
                <p className="ml-[20px]">1. Additional collateral</p>
                <p className="ml-[20px]">2. Third party guarantee</p>
                <li>Proof of cashflow of the asset</li>
                <li>Insurance</li>
              </td>
            </tr>
          </table>
        </Scroll>
      </div>
    </div>
  );
};

export default Asset;
