import React from "react";
import Vector from "../../assets/Vector.svg";
import Back from "../../assets/back.svg";
import { Link } from "react-router-dom";
import Scroll from "../../Reusables/Animation/Scroll";
import { useForm, ValidationError } from "@formspree/react";
const Potential = () => {
  return (
    <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] overflow-hidden">
      <Scroll animationDirection={"left"} possibleClass={""}>
        <div className="py-[6em] sm:py-[8em] px-[2em] sm:px-[10em]">
          <Link to="/sfa/dealbook" className="flex">
            <img src={Back} alt="" />
            <span className="ml-2">Back</span>
          </Link>

          <div className="dealbook-form w-full sm:w-[80%] my-0 px-[2em]">
            <div className="dealbook-heading">
              <h4 className="text-[20px] leading-[28px] text-[#001D45] mb-[1em]">
                Deal Book/Potential Clients
              </h4>
              <p className="text-[18px] leading-[24px]">
                For access to more details about our services, simply provide
                your details below. Ensure to specify your interests and our
                team will contact you.
              </p>
            </div>
            <PotentialForm />
          </div>
        </div>
      </Scroll>
    </div>
  );
};

export default Potential;

function PotentialForm() {
  const [state, handleSubmit] = useForm("xgejgzyn");
  if (state.succeeded) {
    return (
      <div className="md:w-full p-2 bg-[#001D45] h-[50vh] my-10 mx-auto mt-[100px] text-white text-center flex items-center justify-center md:text-5xl flex-col rounded-md">
        <p className="text-center">Thanks for Reaching out to us!</p>
        <p className="text-center">We will get back to you soon.!</p>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
        <label
          className="text-[16px] leading-[24px] text-[#252525]"
          htmlFor="name"
        >
          Name
        </label>
        <input
          className="duration-300 transition-all ease-in-out text-[#252525] rounded-[2px] outline-none border-solid border-[#252525] border-[1px] p-[0.7em]"
          type="text"
          name="name"
          id="name"
          placeholder="Enter name"
        />

        <ValidationError prefix="name" field="name" errors={state.errors} />
      </div>

      <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
        <label
          className="text-[16px] leading-[24px] text-[#252525]"
          htmlFor="name"
        >
          Company Name
        </label>
        <input
          className="duration-300 transition-all ease-in-out text-[#252525] rounded-[2px] outline-none border-solid border-[#252525] border-[1px] p-[0.7em]"
          type="text"
          name="company-name"
          id="company-name"
          placeholder="Enter Company name"
        />
        <ValidationError
          prefix="company-name"
          field="company-name"
          errors={state.errors}
        />
      </div>

      <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
        <label
          className="text-[16px] leading-[24px] text-[#252525]"
          htmlFor="phone number"
        >
          Phone Number
        </label>
        <input
          className="duration-300 transition-all ease-in-out text-[#252525] rounded-[2px] outline-none border-solid border-[#252525] border-[1px] p-[0.7em]"
          type="tel"
          name="phone"
          id="phone"
          placeholder="Enter phone number"
        />
        <ValidationError prefix="phone" field="phone" errors={state.errors} />
      </div>

      <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
        <label
          className="text-[16px] leading-[24px] text-[#252525]"
          htmlFor="company name"
        >
          Email
        </label>
        <input
          className="duration-300 transition-all ease-in-out text-[#252525] rounded-[2px] outline-none border-solid border-[#252525] border-[1px] p-[0.7em]"
          type="email"
          name="email"
          id="email"
          placeholder="Enter email"
        />
        <ValidationError prefix="email" field="email" errors={state.errors} />
      </div>

      <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
        <label
          className="text-[16px] leading-[24px] text-[#252525]"
          htmlFor="location"
        >
          Country
        </label>
        <div className="select-wrapper w-full border-solid border-[1px] border-[#808080] rounded-[2px] transition-all duration-400 ease-in-out">
          <select
            name="location"
            id="location"
            className="text-[#252525] text-[14px] leading-[24px] w-full h-full border-none  outline-none"
          >
            <option value="select-country">Select Country</option>
            <option value="DZ">Algeria</option>
            <option value="AO">Angola</option>
            <option value="BJ">Benin</option>
            <option value="BW">Botswana</option>
            <option value="BF">Burkina Faso</option>
            <option value="BI">Burundi</option>
            <option value="CM">Cameroon</option>
            <option value="CV">Cape Verde</option>
            <option value="CF">Central African Republic</option>
            <option value="TD">Chad</option>
            <option value="KM">Comoros</option>
            <option value="CG">Congo</option>
            <option value="CD">Congo, Democratic Republic of the Congo</option>
            <option value="CI">Cote D'Ivoire</option>
            <option value="DJ">Djibouti</option>
            <option value="EG">Egypt</option>
            <option value="GQ">Equatorial Guinea</option>
            <option value="ER">Eritrea</option>
            <option value="ET">Ethiopia</option>
            <option value="GA">Gabon</option>
            <option value="GM">Gambia</option>
            <option value="GH">Ghana</option>
            <option value="GN">Guinea</option>
            <option value="GW">Guinea-Bissau</option>
            <option value="KE">Kenya</option>
            <option value="LS">Lesotho</option>
            <option value="LR">Liberia</option>
            <option value="LY">Libyan Arab Jamahiriya</option>
            <option value="MG">Madagascar</option>
            <option value="MW">Malawi</option>
            <option value="ML">Mali</option>
            <option value="MR">Mauritania</option>
            <option value="MU">Mauritius</option>
            <option value="YT">Mayotte</option>
            <option value="MA">Morocco</option>
            <option value="MZ">Mozambique</option>
            <option value="NA">Namibia</option>
            <option value="NE">Niger</option>
            <option value="NG">Nigeria</option>
            <option value="RE">Reunion</option>
            <option value="RW">Rwanda</option>
            <option value="SH">Saint Helena</option>
            <option value="ST">Sao Tome and Principe</option>
            <option value="SN">Senegal</option>
            <option value="SC">Seychelles</option>
            <option value="SL">Sierra Leone</option>
            <option value="SO">Somalia</option>
            <option value="ZA">South Africa</option>
            <option value="SS">South Sudan</option>
            <option value="SD">Sudan</option>
            <option value="SZ">Swaziland</option>
            <option value="TZ">Tanzania, United Republic of</option>
            <option value="TG">Togo</option>
            <option value="TN">Tunisia</option>
            <option value="UG">Uganda</option>
            <option value="EH">Western Sahara</option>
            <option value="ZM">Zambia</option>
            <option value="ZW">Zimbabwe</option>
          </select>
        </div>

        <ValidationError
          prefix="location"
          field="location"
          errors={state.errors}
        />
      </div>

      <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
        <label
          className="text-[16px] leading-[24px] text-[#252525]"
          htmlFor="service"
        >
          Services
        </label>
        <div className="select-wrapper w-full border-solid border-[1px] border-[#808080] rounded-[2px] transition-all duration-400 ease-in-out">
          <select
            name="service"
            id="service"
            className="text-[#252525] text-[14px] leading-[24px] w-full h-full border-none  outline-none"
          >
            <option value="select-service">Select Service</option>
            {[
              "Financial Advisory",
              "Capital Raising/ Arrangement",
              "Transaction Advisory (Merger and Acquisition, Project Advisory)",
              "⁠Business Structure Advisory/ Incubation",
              "⁠Principal Investment / Equity Capital",
              "⁠Finance Services Advisory",
            ].map((opt, i) => (
              <option value={opt} key={i}>
                {opt}
              </option>
            ))}
          </select>
        </div>
        <ValidationError
          prefix="service"
          field="service"
          errors={state.errors}
        />
      </div>

      <button className="early-career-btn flex items-center border w-[131px] hover:w-[189px] hover:bg-[#3db2ed] cursor-pointer flex mt-[1.5em] py-[15px] px-[30px] transition-all duration-500 ease-in-out bg-[#0177B2] h-[62px] text-[24px] leading-[32px] text-[white]">
        <span className="mr-[20px]">Submit</span>
        <span>
          <img className="btn-img ml-[10px]" src={Vector} alt="" />
        </span>
      </button>
    </form>
  );
}
