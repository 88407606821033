import React from "react";
import Scroll from "../../Reusables/Animation/Scroll";
import "./index.scss";
const Fund = () => {
  const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className=" h-[60vh] relative top-[65px] sm:h-screen w-full  bg-[black]">
          <img
            src={test.Fund}
            alt=""
            className=" h-full opacity-70 w-full  object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Sthenic Funds
            </h2>
          </div>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] fund-main px-[2em] sm:px-[4em] py-[3em] overflow-hidden">
        <Scroll animationDirection={"left"} possibleClass="about-the-fund sect">
          <p className="my-[15px] text-justify">
            Sthenic Capital Management portfolio includes a wide range of funds
            that offer organizations financial sustainability and development at
            levels of execution structure, speed, and cost that are unrivalled
            globally.
          </p>
          <h4 className="text-[30px] font-[600] text-[#001d45e6]">
            About the Fund
          </h4>
          <p className="my-[15px] text-justify">
            Our Funds invest in a variety of industries, including Food and
            Beverage, Technology, Media and Telecoms, Healthcare, Logistics,
            Agric and Agro Allied, and Real Estate. We are attracted to
            businesses with seek to overcome challenges in areas such as working
            capital, asset growth, procurement, long-term financial stability,
            cash flow management, and other temporary occurrences that may have
            significant impacts on their operations. With a focus on both
            statutory and technical seniority measures, we prioritise systematic
            investments that provide a combination of recurring income and
            capital gains, as well as substantial downside protection.
          </p>
        </Scroll>
      </div>
    </div>
  );
};

export default Fund;
