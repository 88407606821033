import React, { useEffect } from "react";
import MainHeader from "../Reusables/Header/MainHeader";
import Footer from "../Reusables/Footer/Footer";
import Welcome from "./components/Welcome";
import Enquiry from "../Reusables/Enquiry/Enquiry";
const LandingPage = () => {
  useEffect(() => {
    document.title = "Welcome to Sthenic";
  }, []);
  return (
    <div>
      <MainHeader />
      <Welcome />
      <Enquiry />
      <Footer />
    </div>
  );
};

export default LandingPage;
