import React from "react";
import { Link } from "react-router-dom";
import Back from "../../assets/back.svg";
import Vector from "../../assets/Vector.svg";
import Career from "../../assets/Vector.svg";
import Scroll from "../../Reusables/Animation/Scroll";

import { useForm, ValidationError } from "@formspree/react";
import "./index.scss";

const SCMHire = () => {  const [state, handleSubmit] = useForm("xgejgzyn");
// const test = JSON.parse(localStorage.getItem("imgs"));
if (state.succeeded) {
  return (
    <div className="md:w-1/2 bg-[#001D45] h-[50vh] my-10 mx-auto mt-[100px] text-white text-center flex items-center justify-center md:text-5xl flex-col rounded-md">
      <p>Thanks for Reaching us out!</p>
      <p>We will get back to you soon.!</p>
    </div>
  );
}
  return (
    <div>
      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] early-career-container py-[4em] px-[2em]  sm:px-[10em] block sm:flex justify-between gap-[2em] overflow-hidden">
        <Scroll
          animationDirection={"left"}
          possibleClass="early-career-content md:w-[55%] sm:w-[70%] ml-0 w-full sm:ml-[3em] mt-[2em]"
        >
          <Link
            to="/scm/careers"
            className="back flex items-center mb-[1.5em] text-[16px] relative z-[4] leading-[24px] text-[#252525]"
          >
            <img src={Back} alt="" className="mr-[1em] w-[15px]" />
            <p>Back</p>
          </Link>

          <div className="early-career-text text-[#252525]">
            <h3 className="text-[24px] leading-[32px] font-[800]">
              Experienced Hire roles Careers Application Page
            </h3>
            <p className="my-[2em] mx-0 text-[14px] leading-[24px]">
              Welcome to our Experience Careers application page! We are excited
              that you are considering joining our team of finance experts.
              Please submit your application below. We look forward to knowing
              you better
            </p>
          </div>

          <form className="early-career-form" onSubmit={handleSubmit}>
            <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
              <label
                htmlFor="location"
                className="text-[16px] leading-[24px] text-[#252525]"
              >
                Location
              </label>

              <div className="select-wrapper w-full border-solid border-[1px] border-[#808080] rounded-[2px] transition-all duration-400 ease-in-out">
                <select
                  name="location"
                  id="location"
                  className="text-[#252525] text-[14px] leading-[24px] w-full h-full border-none  outline-none"
                >
                  <option value="select-country">Select Country</option>
                  <option value="AO">Angola</option>
                  <option value="BJ">Benin</option>
                  <option value="BW">Botswana</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="CM">Cameroon</option>
                  <option value="CV">Cape Verde</option>
                  <option value="CF">Central African Republic</option>
                  <option value="TD">Chad</option>
                  <option value="KM">Comoros</option>
                  <option value="CG">Congo</option>
                  <option value="CD">
                    Congo, Democratic Republic of the Congo
                  </option>
                  <option value="CI">Cote D'Ivoire</option>
                  <option value="DJ">Djibouti</option>
                  <option value="EG">Egypt</option>
                  <option value="GQ">Equatorial Guinea</option>
                  <option value="ER">Eritrea</option>
                  <option value="ET">Ethiopia</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GH">Ghana</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="KE">Kenya</option>
                  <option value="LS">Lesotho</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libyan Arab Jamahiriya</option>
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="ML">Mali</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="YT">Mayotte</option>
                  <option value="MA">Morocco</option>
                  <option value="MZ">Mozambique</option>
                  <option value="NA">Namibia</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="RE">Reunion</option>
                  <option value="RW">Rwanda</option>
                  <option value="SH">Saint Helena</option>
                  <option value="ST">Sao Tome and Principe</option>
                  <option value="SN">Senegal</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SO">Somalia</option>
                  <option value="ZA">South Africa</option>
                  <option value="SS">South Sudan</option>
                  <option value="SD">Sudan</option>
                  <option value="SZ">Swaziland</option>
                  <option value="TZ">Tanzania, United Republic of</option>
                  <option value="TG">Togo</option>
                  <option value="TN">Tunisia</option>
                  <option value="UG">Uganda</option>
                  <option value="EH">Western Sahara</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </select>
              </div>
            </div>

            <ValidationError
                  prefix="Location"
                  field="location"
                  errors={state.errors}
                />

            <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
              <label
                htmlFor="Job Type"
                className="text-[16px] leading-[24px] text-[#252525]"
              >
                Job Type
              </label>
              <div className="select-wrapper w-full border-solid border-[1px] border-[#808080] rounded-[2px] transition-all duration-400 ease-in-out">
                <select
                  name="job-type"
                  id="job-type"
                  className="text-[#252525] text-[14px] leading-[24px] w-full h-full border-none  outline-none"
                >
                  <option value="select-job">Select Job Type</option>
                  <option value="Designer">Full Time</option>
                  <option value="Designer">Part-time</option>
                  <option value="Designer">Contract based</option>
                </select>
              </div>

              <ValidationError
                  prefix="Job-Type"
                  field="job-type"
                  errors={state.errors}
                />
            </div>

            <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
              <label
                htmlFor="Business-Unit"
                className="text-[16px] leading-[24px] text-[#252525]"
              >
                Business Unit
              </label>
              <div className="select-wrapper w-full border-solid border-[1px] border-[#808080] rounded-[2px] transition-all duration-400 ease-in-out">
                <select
                  name="business-Unit"
                  id="business-Unit"
                  className="text-[#252525] text-[14px] leading-[24px] w-full h-full border-none outline-none"
                >
                  <option value="select-unit">Select Division/Unit</option>
                  <option value="unit1">Advisory Services</option>
                  <option value="unit2">Fund Management</option>
                  <option value="unit3">Financial Control</option>
                  <option value="unit4">Internal Control</option>
                  <option value="unit5">Shared Services</option>
                </select>
              </div>

              <ValidationError
                  prefix="business-Unit"
                  field="business-Unit"
                  errors={state.errors}
                />
            </div>

            <button className="early-career-btn flex items-center border w-[131px] hover:w-[189px] hover:bg-[#3db2ed] cursor-pointer flex mt-[1.5em] py-[15px] px-[30px] transition-all duration-500 ease-in-out bg-[#0177B2] h-[62px] text-[24px] leading-[32px] text-[white]" type="submit">
              <span className="mr-[20px]">Submit</span>
              <span>
                <img className="btn-img ml-[10px]" src={Vector} alt="" />
              </span>
            </button>
          </form>
        </Scroll>
      </div>

      <div className="early-career-img">
        <img src={Career} alt="" />
      </div>
    </div>
  );
};

export default SCMHire;
