import React,{useEffect} from "react";
import SCMHeader from "../Reusables/Header/SCMHeader";
import Footer from "../Reusables/Footer/Footer";
import Lending from "./components/Lending";
const LendingFinance = () => {
  useEffect(() => {
    document.title = "Leading Finance";
  }, []);
  return (
    <div>
      <SCMHeader />
      <Lending />
      <Footer />
    </div>
  );
};

export default LendingFinance;
