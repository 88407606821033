import React,{useEffect} from 'react'
import Investor from './components/Investor'
import SCMHeader from '../Reusables/Header/SCMHeader'
import Footer from '../Reusables/Footer/Footer'
const Investors = () => {
  useEffect(() => {
    document.title = "Investors";
  }, []);
  return (
    <div>
        <SCMHeader/>
        <Investor/>
        <Footer/>
    </div>
  )
}

export default Investors