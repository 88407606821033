import React,{useEffect} from "react";
import SCMHeader from "../Reusables/Header/SCMHeader";
import Footer from "../Reusables/Footer/Footer";
import Contact1 from "./components/Contact1";
const SCMContact1 = () => {
  useEffect(() => {
    document.title = "SCM Contact";
  }, []);
  return (
    <div>
      <SCMHeader />
      <Contact1 />
      <Footer/>
    </div>
  );
};

export default SCMContact1;
