import React, {useEffect} from 'react'
import MainHeader from '../Reusables/Header/MainHeader'
import Enquiry from '../Reusables/Enquiry/Enquiry'
import Footer from '../Reusables/Footer/Footer'
import Insight from './components/Insight'
const Insights = () => {
  useEffect(() => {
    document.title = "Insights";
  }, []);
  return (
    <div>
        <MainHeader/>
        <Insight/>
        <Enquiry/>
        <Footer/>
    </div>
  )
}

export default Insights