import React,{useEffect} from "react";
import SFAHeader from "../Reusables/Header/SFAHeader";
import ABout from "./components/ABout";
import Footer from "../Reusables/Footer/Footer";
const SFAAbout = () => {
  useEffect(() => {
    document.title = "About SFA";
  }, []);
  return (
    <div>
      <SFAHeader />
      <ABout />
      <Footer />
    </div>
  );
};

export default SFAAbout;
