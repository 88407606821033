import React,{useEffect} from "react";
import VentCapital from "./components/VentCapital";
import SCMHeader from "../Reusables/Header/SCMHeader";
import Footer from "../Reusables/Footer/Footer";
const VentureCapital = () => {
  useEffect(() => {
    document.title = "Venture Capital";
  }, []);
  return (
    <div>
      <SCMHeader />
      <VentCapital />
      <Footer />
    </div>
  );
};

export default VentureCapital;
