import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/logo.jpeg";
import useWindowSize from "../../hooks/useWindowSize";

const SFAHeader = () => {
  const [view, setView] = useState(false);
  const location = useLocation();
  const { width } = useWindowSize();
  return (
    <div className="flex justify-center h-[68px] bg-[#001D45] w-full fixed z-[1]">
      <header className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] flex justify-between items-center h-[68px] w-full bg-[#001D45] text-[white] px-[2em] sm:px-[4em] py-0 fixed z-[1]">
        <div className="relative sm:top-[25px] ">
          <Link className="" to="/">
            <img
              className=" h-[50px] sm:w-[125px] z-[-40]  rounded-[50%] sm:h-[125px]"
              src={Logo}
              alt="logo"
            />
          </Link>
        </div>

        <nav
          className={` ${
            view && width < 641
              ? "block w-[80%]  h-[50vh] transform translate-y-[60%]  transition-all duration-1000 ease-in-out absolute py-[20px] "
              : " sm:block transform h-full translate-y-[-1000%] sm:translate-y-0 w-full  transition-all duration-1000 ease-in-out"
          }  sm:w-[80%] md:w-[70%]  lg:w-[60%] bg-[#001D45]
        }`}
        >
          <ul
            className={`flex flex-col sm:flex-row justify-between items-center h-full`}
          >
            <Link
              className={`text-[12px] md:text-[14px] w-full mb-[10px] py-[10px] sm:py-0 sm:mb-0 sm:w-[20%]  h-full flex justify-end sm:justify-center items-center px-[1em] hover:bg-[#5DDDFF] hover:text-[black] duration-500 transition-all ease-in-out ${
                location.pathname === "/sfa/about-us"
                  ? "bg-[#5DDDFF] text-[black]"
                  : ""
              } `}
              to="/sfa/about-us"
            >
              <li className="h-full flex items-center justify-center">
                About Us
              </li>
            </Link>
            <Link
              className={`text-[12px] md:text-[14px] w-full mb-[10px] py-[10px] sm:py-0 sm:mb-0 sm:w-[20%]  h-full flex justify-end sm:justify-center items-center px-[1em] hover:bg-[#5DDDFF] hover:text-[black] duration-500 transition-all ease-in-out ${
                location.pathname === "/sfa/services"
                  ? "bg-[#5DDDFF] text-[black]"
                  : ""
              } `}
              to="/sfa/services"
            >
              <li className="h-full flex items-center justify-center">
                Our Services
              </li>
            </Link>
            <Link
              className={`text-[12px] md:text-[14px] w-full mb-[10px] py-[10px] sm:py-0 sm:mb-0 sm:w-[20%]  h-full flex justify-end sm:justify-center items-center px-[1em] hover:bg-[#5DDDFF] hover:text-[black] duration-500 transition-all ease-in-out ${
                location.pathname === "/sfa/capabilities"
                  ? "bg-[#5DDDFF] text-[black]"
                  : ""
              } `}
              to="/sfa/capabilities"
            >
              <li className="h-full flex items-center justify-center">
                Our Impact
              </li>
            </Link>
            <Link
              className={`text-[12px] md:text-[14px] w-full mb-[10px] py-[10px] sm:py-0 sm:mb-0 sm:w-[20%]  h-full flex justify-end sm:justify-center items-center px-[1em] hover:bg-[#5DDDFF] hover:text-[black] duration-500 transition-all ease-in-out ${
                location.pathname === "/sfa/dealbook"
                  ? "bg-[#5DDDFF] text-[black]"
                  : ""
              } `}
              to="/sfa/dealbook"
            >
              <li className="h-full flex items-center justify-center">
                Dealbook
              </li>
            </Link>
            <Link
              className={`text-[12px] md:text-[14px] w-full mb-[10px] py-[10px] sm:py-0 sm:mb-0 sm:w-[20%]  h-full flex justify-end sm:justify-center items-center px-[1em] hover:bg-[#5DDDFF] hover:text-[black] duration-500 transition-all ease-in-out ${
                location.pathname === "/sfa/careers"
                  ? "bg-[#5DDDFF] text-[black]"
                  : ""
              } `}
              to="/sfa/careers"
            >
              <li className="h-full flex items-center justify-center">
                Careers
              </li>
            </Link>
            <Link
              className={`text-[12px] md:text-[14px] w-full mb-[10px] py-[10px] sm:py-0 sm:mb-0 sm:w-[20%]  h-full flex justify-end sm:justify-center items-center px-[1em] hover:bg-[#5DDDFF] hover:text-[black] duration-500 transition-all ease-in-out ${
                location.pathname === "/sfa/contact-us"
                  ? "bg-[#5DDDFF] text-[black]"
                  : ""
              } `}
              to="/sfa/contact-us"
            >
              <li className="h-full flex items-center justify-center">
                Contact Us
              </li>
            </Link>
          </ul>
        </nav>

        <div
          className=" block sm:hidden none cursor-pointer"
          onClick={() => setView((prev) => !prev)}
        >
          <div
            className={`${
              view
                ? "transform translate-y-[5px] rotate-[45deg] transition-all duration-900 ease-in-out"
                : ""
            }  h-[4px] bg-[white] rounded-[4px] w-[30px] mb-[6px]`}
          ></div>
          <div
            className={`  ${
              view
                ? "fixed left-[-100%] transition-all duration-600 ease-in-out"
                : ""
            } bar h-[4px] bg-[white] rounded-[4px] w-[30px] mb-[6px]`}
          ></div>
          <div
            className={`${
              view
                ? "transform translate-y-[-5px] rotate-[-45deg] transition-all duration-900 ease-in-out"
                : ""
            } h-[4px] bg-[white] rounded-[4px] w-[30px] mb-[6px]`}
          ></div>
        </div>
      </header>
    </div>
  );
};

export default SFAHeader;
