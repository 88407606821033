import React,{useEffect} from 'react'
import Career from './components/Career'
import SCMHeader from '../Reusables/Header/SCMHeader'
import Footer from '../Reusables/Footer/Footer'
const SCMCareer = () => {
  useEffect(() => {
    document.title = "SCM Career";
  }, []);
  return (
    <div>
        <SCMHeader/>
        <Career/>
        <Footer/>
        </div>
  )
}

export default SCMCareer