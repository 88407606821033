import React,{useEffect} from "react";
import MainHeader from "../Reusables/Header/MainHeader";
import Footer from "../Reusables/Footer/Footer";
import Contact2 from "./components/Contact2";
const ContactUs2 = () => {
  useEffect(() => {
    document.title = "Contact Us";
  }, []);
  return (
    <div>
      <MainHeader />
      <Contact2 />
      <Footer />
    </div>
  );
};

export default ContactUs2;
