import React from "react";
import { Link } from "react-router-dom";
// import Shake from "../../assets/shake.png";
import Vector from "../../assets/Vector.svg";
import Scroll from "../../Reusables/Animation/Scroll";
// import Carer from "../../assets/img/Career.jpeg"
const Career = () => {
  const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className="h-[60vh] relative top-[65px] sm:h-screen w-full  bg-[black]">
          <img
            src={test.Carer}
            alt=""
            className=" h-full opacity-70 w-full  object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Careers
            </h2>
            <p className="text-[16px] sm:text-[18px] text-justify">
              At Sthenic, we prioritise creativity, empathy, and
              professionalism. Personal development is vital to us, and we
              ensure that measures are put in place to support career growth.
            </p>
          </div>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] career-container px-[2em] sm:px-[4em] py-[4em] overflow-hidden">
        <Scroll
          animationDirection={"left"}
          possibleClass="career-header text-[#001D45] mt-[2em]"
        >
          <h2 className="text-[40px] leading-[48px]">
            Be a part of something big!
          </h2>
          <p className="text-[16px] leading-[24px]">
            At Sthenic, we promote creativity, empathy, and professionalism.
            Personal development is also vital to us. Our company always ensures
            that we put measures in place to support career growth.
          </p>
          <p className="text-[16px] leading-[24px]">
            This has made us develop a pool of talented individuals who succeed,
            both academically and professionally in different areas of
            specialisation.
          </p>
          <p className="text-[16px] leading-[24px]">
            Join Sthenic and propel us towards the pathway of filling the gap.
          </p>
        </Scroll>

        <div className="career-main w-full sm:w-[75%] my-[3em] mx-auto">
          {/* <div className="career-main-img mb-[2em]">
            <img src={Shake} alt="" className="w-full block h-[336px]" />
          </div> */}
          <div className=" flex  justify-center items-center">
            <div className="career-card-flex  flex flex-col sm:flex-row justify-between">
              <Scroll
                animationDirection={"left"}
                possibleClass="career-card mb-[30px] sm:mb-0 w-full sm:w-[46%] text-[#001D45]"
              >
                <h3 className="career-card-title text-[22px] leading-[24px]">
                  Early Career
                </h3>

                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  Are you desirous of kick-starting your career in finance?
                </p>
                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  With our exciting opportunities, we provide a work environment
                  that fosters professional growth and ensures exposure to a
                  wide range of financial services.
                </p>
                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  We are looking for driven and motivated individuals looking to
                  startup their careers in the finance industry.{" "}
                </p>

                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  To become a part of our dynamic team.
                </p>

                <Link
                  to={"/early-career"}
                  className="early-career-btn flex items-center w-[70%]   sm:w-[80%] border hover:bg-[#3db2ed] cursor-pointer flex mt-[1.5em] py-[10px] px-[25px] transition-all duration-500 ease-in-out bg-[#0177B2]  text-[24px] leading-[32px] text-[white]"
                >
                  <span className="mr-[20px] text-[18px]">Apply Here</span>
                  <span>
                    <img className="btn-img ml-[30px]" src={Vector} alt="" />
                  </span>
                </Link>
              </Scroll>

              <Scroll
                animationDirection={"right"}
                possibleClass="career-card sect w-full sm:w-[46%] text-[#001D45]"
              >
                <h3 className="career-card-title text-[22px] leading-[24px]">
                  Experienced Hire Roles
                </h3>
                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  Are you a motivated individual with proven expertise in
                  finance?
                </p>
                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  With our diverse range of financial services, we provide a
                  dynamic work environment that will take your career to that
                  next phase.
                </p>
                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  We are looking for experienced professionals who are driven to
                  take their careers in finance to the next level.
                </p>
                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  To become one of our talents
                </p>

                <Link
                  to={"/career-hire"}
                  className="early-career-btn flex items-center w-[70%]   sm:w-[80%] border hover:bg-[#3db2ed] cursor-pointer flex mt-[1.5em] py-[10px] px-[25px] transition-all duration-500 ease-in-out bg-[#0177B2]  text-[24px] leading-[32px] text-[white]"
                >
                  <span className="mr-[20px] text-[18px]">Apply Here</span>
                  <span>
                    <img className="btn-img ml-[30px]" src={Vector} alt="" />
                  </span>
                </Link>
              </Scroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
