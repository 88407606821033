import React from "react";
import Chart from "../../assets/image 2.png";
import Scroll from "../../Reusables/Animation/Scroll";
const Structure = () => {
  return (
    <div>
      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] structure-diagram px-[2em] sm:px-[4em] py-[2em] flex flex-col items-center justify-center mt-[4em] overflow-hidden">
        <div
          className="structure-heading mb-[2em] text-center"
          data-aos="fade-right"
        >
          <h3 className="text-[36px]">Our Fund Structure</h3>
        </div>

        <Scroll
          animationDirection={"right"}
          possibleClass="structure-img w-full flex justify-center pb-[50px]"
        >
          <img className="chart" src={Chart} alt="" />
        </Scroll>
      </div>
    </div>
  );
};

export default Structure;
