import React from "react";
import { Link } from "react-router-dom";
import Man from "../../assets/Rectangle 3.png";
const Enquiry = () => {
  return (
    <div className="bg-[#5DDDFF]">
      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] overflow-hidden  text-[#001D45] px-[2em] sm:px-[4em] py-[2em] h-full flex justify-between items-center">
        <div className="w-full md:w-1/2">
          <h3 className="text-[30px] sm:text-[48px]  leading-[56px]">
            For more information
          </h3>
          <Link to={"/contact1"} className="cursor-pointer w-[70%] sm:w-[30%]">
            <button className="bg-[#001D45] text-[white] p-[20px] w-[70%] sm:w-[30%]">
              Enquire
            </button>
          </Link>
        </div>
        <div className="hidden md:block w-full sm:w-[40%] left-0">
          <div className="pt-[30px] w-full h-full">
            <img src={Man} alt="" className="w-full h-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
