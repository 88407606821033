import React from "react";
import { Link } from "react-router-dom";
import Vector from "../../assets/Vector.svg";
import Back from "../../assets/back.svg";
import Scroll from "../../Reusables/Animation/Scroll";

import { useForm, ValidationError } from "@formspree/react";

const Investor = () => {
  return (
    <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] overflow-hidden">
      <Scroll animationDirection={"right"} possibleClass={""}>
        <div className=" py-[6em] sm:py-[8em] px-[2em] sm:px-[10em]">
          <Link to="/sfa/dealbook" className="flex">
            <img src={Back} alt="" />
            <span className="ml-2">Back</span>
          </Link>

          <div className="dealbook-form w-full sm:w-[80%] my-0 px-[2em]">
            <div className="dealbook-heading py-[20px]">
              <h4 className="text-[20px] leading-[28px] text-[#001D45] mb-[1em]">
                Deal Book/Investors
              </h4>
              <p className="text-[18px] leading-[24px]">
                We are committed to helping our investors grow their money
                strategically, Send us a message for further inquiries:
              </p>
            </div>
            <DealBookForm />
          </div>
        </div>
      </Scroll>
    </div>
  );
};

export default Investor;

function DealBookForm() {
  const [state, handleSubmit] = useForm("xgejgzyn");
  if (state.succeeded) {
    return (
      <div className="md:w-full p-2 bg-[#001D45] h-[50vh] my-10 mx-auto mt-[100px] text-white text-center flex items-center justify-center md:text-5xl flex-col rounded-md">
        <p className="text-center">Thanks for Reaching out to us!</p>
        <p className="text-center">We will get back to you soon.!</p>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
        <label
          className="text-[16px] leading-[24px] text-[#252525]"
          htmlFor="name"
        >
          First Name
        </label>
        <input
          className="duration-300 transition-all ease-in-out text-[#252525] rounded-[2px] outline-none border-solid border-[#252525] border-[1px] p-[0.7em]"
          type="text"
          placeholder="Enter name"
          id="first-name"
          name="first-name"
        />
        <ValidationError
          prefix="first-name"
          field="first-name"
          errors={state.errors}
        />
      </div>

      <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
        <label
          className="text-[16px] leading-[24px] text-[#252525]"
          htmlFor="name"
        >
          Last Name
        </label>
        <input
          className="duration-300 transition-all ease-in-out text-[#252525] rounded-[2px] outline-none border-solid border-[#252525] border-[1px] p-[0.7em]"
          type="text"
          name="last-name"
          id="last-name"
          placeholder="Enter last name"
        />
        <ValidationError
          prefix="last-name"
          field="last-name"
          errors={state.errors}
        />
      </div>

      <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
        <label
          className="text-[16px] leading-[24px] text-[#252525]"
          htmlFor="phone number"
        >
          Phone Number
        </label>
        <input
          className="duration-300 transition-all ease-in-out text-[#252525] rounded-[2px] outline-none border-solid border-[#252525] border-[1px] p-[0.7em]"
          type="tel"
          placeholder="Enter phone number"
          name="phone"
          id="phone"
        />
        <ValidationError prefix="phone" field="phone" errors={state.errors} />
      </div>

      <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
        <label
          className="text-[16px] leading-[24px] text-[#252525]"
          htmlFor="company name"
        >
          Email
        </label>
        <input
          className="duration-300 transition-all ease-in-out text-[#252525] rounded-[2px] outline-none border-solid border-[#252525] border-[1px] p-[0.7em]"
          type="email"
          placeholder="Enter email"
          id="email"
          name="email"
        />
        <ValidationError prefix="email" field="email" errors={state.errors} />
      </div>

      <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
        <label
          className="text-[16px] leading-[24px] text-[#252525]"
          htmlFor="Investment Amount"
        >
          Investment Amount
        </label>
        <input
          className="duration-300 transition-all ease-in-out text-[#252525] rounded-[2px] outline-none border-solid border-[#252525] border-[1px] p-[0.7em]"
          type="text"
          placeholder="Enter amount"
          id="amount"
          name="amount"
        />
        <ValidationError prefix="amount" field="amount" errors={state.errors} />
      </div>

      <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
        <label
          className="text-[16px] leading-[24px] text-[#252525]"
          htmlFor="Planned Date of Investment"
        >
          Planned Date of Investment
        </label>
        <input
          className="duration-300 transition-all ease-in-out text-[#252525] rounded-[2px] outline-none border-solid border-[#252525] border-[1px] p-[0.7em]"
          type="date"
          name="date"
          id="date"
        />
        <ValidationError prefix="date" field="date" errors={state.errors} />
      </div>

      <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
        <label
          className="text-[16px] leading-[24px] text-[#252525]"
          htmlFor="Note to Us"
        >
          Note to Us
        </label>
        <textarea
          className="border-2 border-solid border-red"
          name="note"
          id="note"
          cols="30"
          rows="10"
        ></textarea>

        <ValidationError prefix="note" field="note" errors={state.errors} />
      </div>

      <button className="early-career-btn flex items-center border w-[131px] hover:w-[189px] hover:bg-[#3db2ed] cursor-pointer flex mt-[1.5em] py-[15px] px-[30px] transition-all duration-500 ease-in-out bg-[#0177B2] h-[62px] text-[24px] leading-[32px] text-[white]">
        <span className="mr-[20px]">Submit</span>
        <span>
          <img className="btn-img ml-[10px]" src={Vector} alt="" />
        </span>
      </button>
    </form>
  );
}
