import React, { useEffect } from "react";
import SCMHeader from "../Reusables/Header/SCMHeader";
import Fund from "./components/Fund";
import Footer from "../Reusables/Footer/Footer";
import Philosophy from "./components/Philosophy";
import Structure from "./components/Structure";
const Funds = () => {
  useEffect(() => {
    document.title = "Funds";
  }, []);
  return (
    <div>
      <SCMHeader />
      <Fund />
      <Philosophy />
      <Structure />
      <Footer />
    </div>
  );
};

export default Funds;
