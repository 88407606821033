import React from "react";
import Medal from "../../assets/sports-medal.png";
import Scroll from "../../Reusables/Animation/Scroll";
import Logo from "../../assets/logo.jpeg";
import Hist from "../../assets/img/history.jpeg";
const Histor = () => {
  // const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div className="">
      <div className=" h-full relative">
        <div className="h-[60vh] relative top-[65px] sm:h-screen w-full bg-[black]">
          <img
            src={Hist}
            alt=""
            className=" h-full opacity-70 w-full  object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Our History
            </h2>
          </div>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] flex justify-center overflow-hidden">
        <div className="flex flex-col  w-[80%]   py-32 px-[0] sm:px-[4em] ">
          <div className="mb-[40px]">
            <Scroll
              animationDirection={"left"}
              possibleClass="flex flex-col md:flex-row w-[80%] md:w-full text-white mb-4 sect"
            >
              <div className="mr-[30px]">
                <button
                  type="button"
                  className="flex justify-center items-center bg-[#001D45] rounded px-[16px] py-[5px] mb-2 md:mb-0"
                >
                  <img src={Medal} alt="" className="mr-[4px]" />
                  Founded
                </button>
              </div>
              <div className="">
                <button
                  type="button"
                  className="bg-[#001D45] rounded bot px-[16px] py-[5px]"
                >
                  In 2016
                </button>
              </div>
            </Scroll>
            <Scroll
              animationDirection={"right"}
              possibleClass="p-[30px] bg-[#5DDDFF] rounded-[20px] mt-8"
            >
              <h3 className="font-[600] py-4 text-[24px]">
                Sthenic Finance & Advisory (SFA) Incorporated
              </h3>
              <p className="text-[16px] text-justify">
                Sthenic was founded and incorporated by the trio of AND, AOO and
                FOO, as a pure play financial advisory business to find a
                business model in the future
              </p>
            </Scroll>
          </div>
          <div className="mb-[40px]">
            <Scroll
              animationDirection={"left"}
              possibleClass="flex flex-col md:flex-row w-[55%] md:w-[40%] text-white mb-4 sect"
            >
              <div className="mr-[30px]">
                <button
                  className="flex justify-center items-center bg-[#001D45] rounded px-[16px] py-[5px] mb-2 md:mb-0"
                  type="button"
                >
                  <img src={Medal} alt="" className="mr-[4px]" />
                  Established
                </button>
              </div>
              <div className="">
                <button
                  type="button"
                  className="bg-[#001D45] rounded bot px-[16px] py-[5px]"
                >
                  In 2019
                </button>
              </div>
            </Scroll>
            <Scroll
              animationDirection={"right"}
              possibleClass="p-[30px] bg-[#5DDDFF] rounded-[20px] mt-8"
            >
              <h3 className="font-[600] py-4 text-[24px]">
                SFA commences Operation as a Finance & Investing Business in
                March 2019:
              </h3>

              <ul>
                <li className="flex items-center my-2">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                  Commenced full operations and hired the first set of staff in
                  March
                </li>
                <li className="flex items-center my-2">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                  Signed first engagement also in March
                </li>
                <li className="flex items-center my-2">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                  In May, it reached first close of SSDF I
                </li>
                <li className="flex items-center my-2">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                  Equally in the same May, it made its first investment in SSDF
                  I.
                </li>
                <li className="flex items-center my-2">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                  By August, it attained its first close of SOF
                </li>
                <li className="flex items-center my-2">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                  In the year in review, it closed a US$5m equity raised for
                  Fina Trust
                </li>
              </ul>
            </Scroll>
          </div>
          <div className="mb-[40px]">
            <Scroll
              animationDirection={"left"}
              possibleClass="flex flex-col md:flex-row text-white mb-4 sect"
            >
              <div className="mr-[30px]">
                <button className="flex justify-center items-center bg-[#001D45] rounded px-[16px] py-[5px] mb-2 md:mb-0">
                  <img src={Medal} alt="" className="mr-[4px]" />
                  Coast-Up
                </button>
              </div>
              <div className="">
                <button className="bg-[#001D45] bot rounded px-[16px] py-[5px]">
                  2020
                </button>
              </div>
            </Scroll>
            <Scroll
              animationDirection={"right"}
              possibleClass="p-[30px] bg-[#5DDDFF] rounded-[20px] mt-8"
            >
              <h3 className="font-[600] py-4 text-[24px]">
                The Covid Year; Sthenic Capital Management Ltd was Incorporated
              </h3>
              <p className="text-[16px] text-justify">
                Despite the challenges brought about by the outbreak of the
                pandemic, Sthenic Capital Management was established and
                incorporated. The company came to limelight because of the need
                to provide sustainable financing to small- and medium-scale
                businesses.
              </p>
            </Scroll>
          </div>
          <div className="mb-[40px]">
            <Scroll
              animationDirection={"left"}
              possibleClass="flex flex-col md:flex-row  text-white mb-4 sect"
            >
              <div className="mr-[30px]">
                <button className="flex justify-center items-center bg-[#001D45] rounded px-[16px] py-[5px] mb-2 md:mb-0">
                  <img src={Medal} alt="" className="mr-[4px]" />
                  Drifted-Up
                </button>
              </div>
              <div className="">
                <button className="bg-[#001D45] rounded bot px-[16px] py-[5px]">
                  2021
                </button>
              </div>
            </Scroll>
            <Scroll
              animationDirection={"right"}
              possibleClass="p-[30px] bg-[#5DDDFF] rounded-[20px] mt-8"
            >
              <h3 className="font-[600] py-4 text-[24px]">Discovery Year</h3>
              <div className="flex items-center my-2">
                <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                <p className="text-[16px]">The Year of Learning</p>
              </div>
              <div className="flex items-center my-2">
                <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                <p className="text-[16px]">The Year of Growing; differently</p>
              </div>
              <div className="flex items-center my-2">
                <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                <p className="text-[16px]">The year of Knowing.</p>
              </div>
            </Scroll>
          </div>
          <div className="mb-[40px]">
            <Scroll
              animationDirection={"left"}
              possibleClass="flex flex-col md:flex-row w-[65%] md:w-[55%] text-white mb-4 sect"
            >
              <div className="mr-[30px]">
                <button className="flex justify-center items-center bg-[#001D45] rounded px-[16px] py-[5px] mb-2 md:mb-0">
                  <img src={Medal} alt="" className="mr-[4px]" />
                  Intentional Growth
                </button>
              </div>
              <div className="">
                <button className="bg-[#001D45] rounded bot px-[16px] py-[5px]">
                  2022
                </button>
              </div>
            </Scroll>
            <Scroll
              animationDirection={"right"}
              possibleClass="p-[30px] bg-[#5DDDFF] rounded-[20px] mt-8"
            >
              <h3 className="font-[600] py-4 text-[24px]">
                Year Of Intentional Growth
              </h3>
              <p className="text-[16px]">
                The company launched a new fund – SSDF 2.0
              </p>
            </Scroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Histor;
