import React,{useEffect} from 'react'
import Deal from './components/Deal'
import SFAHeader from '../Reusables/Header/SFAHeader'
import Footer from '../Reusables/Footer/Footer'
const DealBook = () => {
  useEffect(() => {
    document.title = "Deal Book";
  }, []);
  return (
    <div>
        <SFAHeader/>
        <Deal/>
        <Footer/>
        </div>
  )
}

export default DealBook