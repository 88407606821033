import React from "react";
import { Link } from "react-router-dom";
// import Shake from "../../assets/shake.png";
import Vector from "../../assets/Vector.svg";
import Scroll from "../../Reusables/Animation/Scroll";
const Career = () => {
  const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className="h-[60vh] relative top-[65px] sm:h-screen w-full  bg-[black]">
          <img
            src={test.Carer}
            alt=""
            className=" h-full opacity-70 w-full object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Careers
            </h2>
          </div>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] career-container  px-[2em] sm:px-[4em] py-[6em] overflow-hidden">
        <Scroll
          animationDirection={"left"}
          possibleClass="career-header text-[#001D45] mt-[2em]"
        >
          <h2 className="text-[40px] leading-[48px]">
            Be Part of Something Big
          </h2>
          <p className="text-[16px] leading-[24px] mb-[10px]">
            At Sthenic, we prioritise creativity, empathy, and professionalism.
            Personal development is vital to us, and we ensure that measures are
            put in place to support career growth.
          </p>
          <p className="text-[16px] leading-[24px] mb-[10px]">
            This has assisted us in developing a talent pool of individuals who
            succeed both academically and professionally in their respective
            fields of specialisation. Join Sthenic and help us lead the path
            toward filling the missing gap.
          </p>
        </Scroll>

        <div className="career-main w-[75%] my-[3em] mx-auto">
          {/* <Scroll animationDirection={"right"} possibleClass="career-main-img mb-[2em]">
            <img src={Shake} alt="" className="w-full block h-[336px]" />
          </Scroll> */}
          <div className=" flex justify-center">
            <div className="career-card-flex  flex justify-between">
              <Scroll
                animationDirection={"left"}
                possibleClass="career-card w-[46%] text-[#001D45]"
              >
                <h3 className="career-card-title text-[22px] leading-[24px]">
                  Early Career
                </h3>

                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  Are you willing to kickstart your career in finance?
                </p>
                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  With our exciting opportunities, we provide a work environment
                  that fosters professional growth and ensures exposure to a
                  wide range of financial services.
                </p>
                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  We are looking for driven and motivated individuals looking to
                  startup their careers in the finance industry.{" "}
                </p>

                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  To become a part of our dynamic team
                </p>

                <Link
                  to="/early-career"
                  id="btnA"
                  className="career-btn text-[24px] leading-[32px] w-[172px] text-[white] py-[15px] px-[30px] bg-[#0177B2] border-1 border-solid border-transparent transition-all duration-500 ease-in-out"
                >
                  <span>Apply Here</span>
                  <span>
                    <img
                      id="btn-imgA"
                      className="btn-img opacity-0 hidden"
                      src={Vector}
                      alt=""
                    />
                  </span>
                </Link>
              </Scroll>

              <Scroll
                animationDirection={"right"}
                possibleClass="career-card sect w-[46%] text-[#001D45]"
              >
                <h3 className="career-card-title text-[22px] leading-[24px]">
                  Experienced Hire Roles
                </h3>
                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  Are you a motivated individual with proven expertise in
                  finance?
                </p>
                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  With our diverse range of financial services, we provide a
                  dynamic work environment that will take your career to that
                  next phase.
                </p>
                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  We are looking for experienced professionals who are driven to
                  take their careers in finance to the next level.
                </p>
                <p className="text-[14px] leading-[24px] mt-[1em] mb-[2em]">
                  To become one of our talents
                </p>
                <Link
                  to="/career-hire"
                  id="btnA"
                  className="career-btn text-[24px] leading-[32px] w-[172px] text-[white] py-[15px] px-[30px] bg-[#0177B2] border-1 border-solid border-transparent transition-all duration-500 ease-in-out"
                >
                  <span>Apply Here</span>
                  <span>
                    <img
                      id="btn-imgA"
                      className="btn-img opacity-0 hidden"
                      src={Vector}
                      alt=""
                    />
                  </span>
                </Link>
              </Scroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
