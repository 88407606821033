import React,{useEffect} from 'react'
import Investor from './components/Investor'
import SFAHeader from '../Reusables/Header/SFAHeader'
import Footer from '../Reusables/Footer/Footer'
const SFAInvestors = () => {
  useEffect(() => {
    document.title = "SFA Investors";
  }, []);
  return (
    <div>
        <SFAHeader/>
        <Investor/>
        <Footer/>
        </div>
  )
}

export default SFAInvestors