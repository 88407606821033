import React,{useEffect} from "react";
import SCMHeader from "../Reusables/Header/SCMHeader";
import Footer from "../Reusables/Footer/Footer";
import Investee from "./components/Investee";
const Investees = () => {
  useEffect(() => {
    document.title = "Investees";
  }, []);
  return (
    <div>
      <SCMHeader />
      <Investee /> 
      <Footer />
    </div>
  );
};

export default Investees;
