import React,{useEffect} from 'react'
import MainHeader from "../Reusables/Header/MainHeader"
import Footer from "../Reusables/Footer/Footer"
import Hire from './components/Hire'
const CareerHire = () => {
  useEffect(() => {
    document.title = "Career";
  }, []);
  return (
    <div>
        <MainHeader/>
        <Hire/>
        <Footer/>
    </div>
  )
}

export default CareerHire