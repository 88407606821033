import React,{useEffect} from 'react'
import SCMHeader from '../Reusables/Header/SCMHeader'
import Footer from '../Reusables/Footer/Footer'
import Receive from "./components/Receive"
const Receivable = () => {
  useEffect(() => {
    document.title = "Receivable";
  }, []);
  return (
    <div>
        <SCMHeader/>
        <Receive/>
        <Footer/>
        </div>
  )
}

export default Receivable