import React from "react";
import Placeholder from "../../assets/logo.jpeg";
import Image1 from "../../assets/RidGe.png";
import Image2 from "../../assets/ADDOSSER_FINANCE.png";
import Image3 from "../../assets/FINA.jpeg";
import Image4 from "../../assets/BETTER_GAS.png";
import Image5 from "../../assets/PRECIOSO.jpg";
import Image6 from "../../assets/AYDEN.png";
import Image7 from "../../assets/hoop-telecoms.png";
import Image8 from "../../assets/cropped-logo.png";
import Image9 from "../../assets/cloudflex.webp";
import Image10 from "../../assets/DIVERSE-AUTOCARE.jpg";
import Image11 from "../../assets/kenol-logo.png";
import Image12 from "../../assets/Onaturals.png";
import Image13 from "../../assets/NodCredit.png";
import Image14 from "../../assets/NAVEEN.png";
import Image15 from "../../assets/MEGAPLUX.webp";

function ImpactsTable() {
  const projects = [
    {
      image: Image1,
      category: "Portfolio - Exit",
      dealType: "Working Capital Refinancing",
      size: "30,000,000.00",
      sector: "Healthcare",
    },
    {
      image: Image2,
      category: "Advisory",
      dealType: "Equity Capital Raise",
      size: "1,500,000,000.00",
      sector: "Microfinance Bank",
    },
    {
      image: Image3,
      category: "Capital Raise Closed",
      dealType: "Equity Capital Raise",
      size: "1,750,000,000.00",
      sector: "Microfinance Bank",
    },
    {
      image: Image4,
      category: "Advisory - closed",
      dealType: "Advisor on Local Content Capital Raise",
      size: " 3,600,000,000.00",
      sector: "LPG Energy",
    },
    {
      image: Image7,
      category: "Merger and Acquisition",
      dealType: "Transaction Advisory",
      size: "5,300,000,000.00",
      sector: "Telecommunication",
    },
    {
      image: Image5,
      category: "Project",
      dealType: "LBO Financing",
      size: "200,000,000.00",
      sector: "Real Estate",
    },
    {
      image: Image6,
      category: "Project",
      dealType: "Project Financing",
      size: "80,000,000.00",
      sector: "Auto mobile repair shop",
    },
    {
      image: Image14,
      category: "Portfolio",
      dealType: "Project Financing",
      size: "15,000,000.00",
      sector: "Healthcare",
    },
    {
      image: Image8,
      category: "Portfolio - Exit",
      dealType: "Project Financing",
      size: "100,000,000.00",
      sector: "Real Estate",
    },
    {
      image: Image9,
      category: "Portfolio - Exit",
      dealType: "Working Capital Refinancing",
      size: "50,000,000.00",
      sector: "Cloud Technology",
    },
    {
      image: Image13,
      category: "Portfolio - Exit",
      dealType: "Trading Financing",
      size: "100,000,000.00",
      sector: "FinTech",
    },
    {
      image: Image10,
      category: "Advisory - closed",
      dealType: "Business Incubation and Capital Raise",
      size: "380,000,000.00",
      sector: "Auto mobile repair shop",
    },
    {
      image: Image11,
      category: "Portfolio - Exit",
      dealType: "Purchase Order Financing",
      size: "90,000,000.00",
      sector: "Energy Engineering",
    },
    {
      image: Image12,
      category: "Portfolio - Exit",
      dealType: "Hair Salon",
      size: "30,000,000.00",
      sector: "Fashion",
    },
    {
      image: Placeholder,
      category: "Portfolio - Exit",
      dealType: "Working Capital Financing",
      size: "62,500,000.00",
      sector: "HealthCare",
    },
    {
      image: Image15,
      category: "Portfolio - Exit",
      dealType: "Purchase Order Financing",
      size: "50,000,000.00",
      sector: "Fabrication",
    },
  ];

  return (
    <div className="container mx-auto py-10">
      <div className="relative bg-white w-full">
        <table border className="bg-white w-full border">
          <thead>
            <tr className="bg-white text-black">
              <th className="py-5 border">Company</th>
              <th className="py-5 border">Category</th>
              <th className="py-5 border">Deal Type</th>
              <th className="py-5 border">Size - Cumm</th>
              <th className="py-5 border">Sector</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((e, i) => (
              <tr key={i} className="bg-white text-black">
                <td className="text-center py-5 flex items-center justify-center border">
                  <img
                    src={e.image}
                    alt=""
                    className="w-[150px] h-[100px] object-contain"
                  />
                </td>
                <td className="text-center border">{e.category}</td>
                <td className="text-center border">{e.dealType}</td>
                <td className="text-center border">{e.size}</td>
                <td className="text-center border">{e.sector}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ImpactsTable;
