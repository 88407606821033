import React,{useEffect} from 'react'
import SCMHeader from '../Reusables/Header/SCMHeader'
import Invoice from './components/Invoice'
import Footer from '../Reusables/Footer/Footer'
const InvoiceDiscount = () => {
  useEffect(() => {
    document.title = "Invoice Discount";
  }, []);
  return (
    <div>
        <SCMHeader/>
        <Invoice/>
        <Footer/>
        </div>
  )
}

export default InvoiceDiscount