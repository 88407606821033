import React from "react";
import Goals from "../../assets/investing-goals.jpeg";
import Foreign from "../../assets/foreign.jpeg";
import Merit from "../../assets/merit.png";
import MoneyTalk from "../../assets/Money-talks.jpeg";
import Vector from "../../assets/Vector.svg";
import FinTech from "../../assets/financial-tech.jpeg";
import { Link } from "react-router-dom";
import Scroll from "../../Reusables/Animation/Scroll";
const Insight = () => {
  const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div className="">
      <div className="relative h-full">
        <div className=" h-[70vh] sm:h-screen w-full bg-[black]">
          <img
            src={test.Insigh}
            alt=""
            className=" h-full opacity-70 w-full  object-cover object-center"
          />
        </div>
        <div className=" p-[2em]  sm:p-[4em] absolute top-[12%] left-[5%] sm:left-[8%] text-[white] w-[90%] sm:w-[70%]">
          <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">Insight</h2>

          <p className="text-[18px] sm:text-[24px] mt-10">
            A global investment banking, asset management, and advisory firm
            dedicated to providing value to clients in emerging and frontier
            markets.
          </p>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] sm:px-[4em] py-[2em] overflow-hidden">
        <Scroll animationDirection={"left"} possibleClass=" w-full mb-[2em]">
          <div className="searchbar border-solid border-[1px] border-[#808080] w-full sm:w-[70%] md:w-1/2 flex items-center rounded-[6px] overflow-hidden m-auto">
            <input
              type="text"
              placeholder="Enter Search here"
              className="outline-none text-[#001D45] text-[14px] leading-[28px] w-[70%] h-full m-0 border-none px-[1em] py-[0.5em]"
            />

            <button
              className="w-[30%] m-0 h-full border-none text-[16px] leading-[24px] px-[1em] py-[0.5em] cursor-pointer bg-[#001D45] text-[#fff]"
              id="search-btn"
            >
              search
            </button>
          </div>

          <h3 className=" text-[24px] leading-[28px] my-[1em] mx-0 text-[#001D45]">
            Categories
          </h3>

          <ul className=" m-auto w-full flex flex-col sm:flex-row  justify-between items-start sm:items-center">
            <li className=" my-[10px] sm:my-0">
              <Link
                className=" text-[18px]  sm:text-[14px] leading-[24px]  transition-all duration-300 ease-in-out text-[#5DDDFF] hover:leading-[24px] hover:text-[20px]"
                to="#"
              >
                All
              </Link>
            </li>
            <li className="my-[10px] sm:my-0">
              <Link
                className=" text-[#001D45]  text-[18px] sm:text-[14px] leading-[24px]  transition-all duration-300 ease-in-out hover:text-[#5DDDFF] hover:leading-[24px] hover:text-[20px]"
                to="#"
              >
                Company News
              </Link>
            </li>
            <li className="my-[10px] sm:my-0">
              <Link
                className=" text-[#001D45]  text-[18px] sm:text-[14px] leading-[24px]  transition-all duration-300 ease-in-out hover:text-[#5DDDFF] hover:leading-[24px] hover:text-[20px]"
                to="#"
              >
                Economy
              </Link>
            </li>
            <li className="my-[10px] sm:my-0">
              <Link
                className=" text-[#001D45]  text-[18px] sm:text-[14px] leading-[24px]  transition-all duration-300 ease-in-out hover:text-[#5DDDFF] hover:leading-[24px] hover:text-[20px]"
                to="#"
              >
                Investment
              </Link>
            </li>
            <li className="my-[10px] sm:my-0">
              <Link
                className=" text-[#001D45]  text-[18px] sm:text-[14px] leading-[24px]  transition-all duration-300 ease-in-out hover:text-[#5DDDFF] hover:leading-[24px] hover:text-[20px]"
                to="#"
              >
                Politics
              </Link>
            </li>
            <li className="my-[10px] sm:my-0">
              <Link
                to={"#"}
                className=" text-[#001D45]  text-[18px] sm:text-[14px] leading-[24px] transition-all duration-300 ease-in-out hover:text-[#5DDDFF] hover:leading-[24px] hover:text-[20px]"
              >
                Lifestyle
              </Link>
            </li>
          </ul>
        </Scroll>
        <main className="insights-container w-full">
          <div className="insights-card-wrapper ">
            <Scroll animationDirection={"left"} possibleClass="insight-card ">
              <img src={Goals} alt="" className="w-full" />
              <div className="insight-card-title my-[1em] mx-0">
                <h3>Setting Your Investment Goals</h3>
              </div>
              <div className="insight-card-dets text-[14px] leading-[24px]">
                <p>
                  Before you start investing, it's important to identify your
                  investment goals. Are you investing for long-term growth or
                  short-term gains? Do you want to generate income from your
                  investments, or are you looking for capital appreciation? Once
                  you have Link clear understanding of your investment goals,
                  you can start to create Link plan for achieving them.
                </p>
              </div>

              <Link
                to="#"
                className="read-more text-[#5DDDFF] text-[16px] leading-[24px] border-b-1 border-transparent transition-all duration-400 ease-in-out hover:boder-solid hover:border-1 hover:border-[#5DDDFF]"
              >
                Read more
              </Link>

              <div className="insight-card-foot flex justify-between text-[14px] leading-[24px] text-[#808080]">
                <div className="time">
                  <p>21 mins ago</p>
                </div>
                <div className="views">
                  <p>130 views</p>
                </div>
              </div>
            </Scroll>

            <Scroll
              animationDirection={"right"}
              possibleClass="insight-card sect"
            >
              <img src={Foreign} alt="" />
              <div className="insight-card-title my-[1em] mx-0">
                <h3>
                  Sthenic Global Partners with World Bank to boost Africa’s
                  investme...
                </h3>
              </div>
              <div className="insight-card-dets text-[14px] leading-[24px]">
                <p>
                  Before you start investing, it's important to identify your
                  investment goals. Are you investing for long-term growth or
                  short-term gains? Do you want to generate income from your
                  investments, or are you looking for capital appreciation?
                </p>
              </div>
              <Link
                to="#"
                className="read-more text-[#5DDDFF] text-[16px] leading-[24px] border-b-1 border-transparent transition-all duration-400 ease-in-out hover:boder-solid hover:border-1 hover:border-[#5DDDFF]"
              >
                Read more
              </Link>
              <div className="insight-card-foot flex justify-between text-[14px] leading-[24px] text-[#808080]">
                <div className="time">
                  <p>21 mins ago</p>
                </div>
                <div className="views">
                  <p>130 views</p>
                </div>
              </div>
            </Scroll>

            <Scroll
              animationDirection={"left"}
              possibleClass="insight-card sect"
            >
              <img src={Foreign} alt="" />
              <div className="insight-card-title my-[1em] mx-0">
                <h3>Foreign Currency Investments</h3>
              </div>
              <div className="insight-card-dets text-[14px] leading-[24px]">
                <p>
                  Before you start investing, it's important to identify your
                  investment goals. Are you investing for long-term growth or
                  short-term gains? Do you want to generate income from your
                  investments, or are you looking for capital appreciation? Once
                  you have Link clear understanding of your investment goals,
                  you can start to create Link plan for achieving them.
                </p>
              </div>
              <Link
                to="#"
                className="read-more text-[#5DDDFF] text-[16px] leading-[24px] border-b-1 border-transparent transition-all duration-400 ease-in-out hover:boder-solid hover:border-1 hover:border-[#5DDDFF]"
              >
                Read more
              </Link>
              <div className="insight-card-foot flex justify-between text-[14px] leading-[24px] text-[#808080]">
                <div className="time">
                  <p>21 mins ago</p>
                </div>
                <div className="views">
                  <p>130 views</p>
                </div>
              </div>
            </Scroll>

            <Scroll
              animationDirection="right"
              possibleClass="insight-card sect"
            >
              <img src={Merit} alt="" />
              <div className="insight-card-title my-[1em] mx-0">
                <h3>Global Merit Recognition</h3>
              </div>
              <div className="insight-card-dets text-[14px] leading-[24px]">
                <p>
                  Before you start investing, it's important to identify your
                  investment goals. Are you investing for long-term growth or
                  short-term gains? Do you want to generate income from your
                  investments, or are you looking for capital appreciation? Once
                  you have Link clear understanding of your investment goals,
                  you can start to create Link plan for achieving them.
                </p>
              </div>
              <Link
                to="/"
                className="read-more text-[#5DDDFF] text-[16px] leading-[24px] border-b-1 border-transparent transition-all duration-400 ease-in-out hover:boder-solid hover:border-1 hover:border-[#5DDDFF]"
              >
                Read more
              </Link>
              <div className="insight-card-foot flex justify-between text-[14px] leading-[24px] text-[#808080]">
                <div className="time">
                  <p>21 mins ago</p>
                </div>
                <div className="views">
                  <p>130 views</p>
                </div>
              </div>
            </Scroll>

            <Scroll
              animationDirection={"left"}
              possibleClass="insight-card sect"
            >
              <img src={MoneyTalk} alt="" />
              <div className="insight-card-title my-[1em] mx-0">
                <h3>Money talks</h3>
              </div>
              <div className="insight-card-dets text-[14px] leading-[24px]">
                <p>
                  Before you start investing, it's important to identify your
                  investment goals. Are you investing for long-term growth or
                  short-term gains? Do you want to generate income from your
                  investments, or are you looking for capital appreciation? Once
                  you have Link clear understanding of your investment goals,
                  you can start to create Link plan for achieving them.
                </p>
              </div>
              <Link
                to="/"
                className="read-more text-[#5DDDFF] text-[16px] leading-[24px] border-b-1 border-transparent transition-all duration-400 ease-in-out hover:boder-solid hover:border-1 hover:border-[#5DDDFF]"
              >
                Read more
              </Link>
              <div className="insight-card-foot flex justify-between text-[14px] leading-[24px] text-[#808080]">
                <div className="time">
                  <p>21 mins ago</p>
                </div>
                <div className="views">
                  <p>130 views</p>
                </div>
              </div>
            </Scroll>

            <Scroll
              animationDirection={"right"}
              possibleClass="insight-card sect"
            >
              <img src={FinTech} alt="" />
              <div className="insight-card-title my-[1em] mx-0">
                <h3>The Financial Tech Era</h3>
              </div>
              <div className="insight-card-dets text-[14px] leading-[24px]">
                <p>
                  Before you start investing, it's important to identify your
                  investment goals. Are you investing for long-term growth or
                  short-term gains? Do you want to generate income from your
                  investments, or are you looking for capital appreciation? Once
                  you have Link clear understanding of your investment goals,
                  you can start to create Link plan for achieving them.
                </p>
              </div>
              <Link
                to="/"
                className="read-more text-[#5DDDFF] text-[16px] leading-[24px] border-b-1 border-transparent transition-all duration-400 ease-in-out hover:boder-solid hover:border-1 hover:border-[#5DDDFF]"
              >
                Read more
              </Link>
              <div className="insight-card-foot flex justify-between text-[14px] leading-[24px] text-[#808080]">
                <div className="time">
                  <p>21 mins ago</p>
                </div>
                <div className="views">
                  <p>130 views</p>
                </div>
              </div>
            </Scroll>
          </div>

          <Scroll
            animationDirection={"left"}
            possibleClass="insight-btn mt-[3em]"
          >
            <button className="early-career-btn flex items-center border w-[131px] hover:w-[189px] hover:bg-[#3db2ed] cursor-pointer flex mt-[1.5em] py-[15px] px-[30px] transition-all duration-500 ease-in-out bg-[#0177B2] h-[62px] text-[24px] leading-[32px] text-[white]">
              <span className="mr-[20px]">Next</span>
              <span>
                <img className="btn-img ml-[10px]" src={Vector} alt="" />
              </span>
            </button>
          </Scroll>
        </main>
      </div>
    </div>
  );
};

export default Insight;
