import React from "react";
import Vector from "../../assets/Vector.svg";
import Scroll from "../../Reusables/Animation/Scroll";

import { useForm, ValidationError } from "@formspree/react";

const Investee = () => {
  const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className=" w-full h-[60vh] relative top-[65px] sm:h-screen  bg-[black]">
          <img
            src={test.Img144}
            alt=""
            className=" h-full opacity-70 w-full  object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Data Room
            </h2>
          </div>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] investor-main px-[2em] sm:px-[4em] py-[3em] overflow-hidden">
        <div className="investors py-[5em]">
          <Scroll animationDirection={"left"}>
            <h3 className="text-[40px] leading-[48px] text-[#001D45]">
              Data Room
            </h3>
            <p className="text-justify">
              To access detailed information regarding our funds simply provide
              your details below, specify your interests and our team will
              contact you.
            </p>
          </Scroll>
          <Scroll animationDirection={"right"}><InvesteeForm/>
                     </Scroll>
        </div>
      </div>
    </div>
  );
};

export default Investee;


function InvesteeForm(){

  const [state, handleSubmit] = useForm("xgejgzyn");
  if (state.succeeded) {
    return (
      <div className="md:w-1/2 bg-[#001D45] h-[50vh] my-10 mx-auto mt-[100px] text-white text-center flex items-center justify-center md:text-5xl flex-col rounded-md">
        <p>Thanks for Contacting us!</p>
        <p>We will get back to you soon.!</p>
      </div>
    );
  }
  return  <fieldset className="py-[2em] px-[1em] md:px-[6em]  sm:py-[3em] sm:px-[2em]  w-full sm:w-[65%]  mt-[3em] mx-auto border-2 border-solid border-[#808080]">
  <legend className="text-center text-[#808080] text-[18px] leading-[24px] ps-[0.5em] pe-[0.5em]">
    Fields Marked * are required
  </legend>
  <form className="early-career-form investor-form" onSubmit={handleSubmit}>
    <div className="form-section flex flex-col gap-[0.3em] mb-[1em]"></div>

    <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
      <label
        className="text-[16px] leading-[24px] text-[#252525]"
        htmlFor=" name"
      >
        Name *
      </label>
      <input
        type="text"
        placeholder="Enter name"
        className="duration-300 transition-all ease-in-out text-[#252525] rounded-[2px] outline-none border-solid border-[#252525] border-[1px] p-[0.7em]"
name="name"
id="name"
      />

<ValidationError
                  prefix="name"
                  field="name"
                  errors={state.errors}
                />
    </div>

    <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
      <label
        className="text-[16px] leading-[24px] text-[#252525]"
        htmlFor="prospective investor"
      >
        Prospective Investor
      </label>
      <input
        type="text"
        placeholder="Select"
        className="duration-300 transition-all ease-in-out text-[#252525] rounded-[2px] outline-none border-solid border-[#252525] border-[1px] p-[0.7em]"
name="prospective-investor"
id="prospective-investor"
      />

<ValidationError
                  prefix="prospective-investor"
                  field="prospective-investor"
                  errors={state.errors}
                />
    </div>

    <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
      <label
        className="text-[16px] leading-[24px] text-[#252525]"
        htmlFor="phone number"
      >
        Company Name
      </label>
      <input
        type="text"
        placeholder="Enter Company"
        className="duration-300 transition-all ease-in-out text-[#252525] rounded-[2px] outline-none border-solid border-[#252525] border-[1px] p-[0.7em]"
name="company-name"
id="company-name"
      />

<ValidationError
                  prefix="company-name"
                  field="company-name"
                  errors={state.errors}
                />
    </div>

    <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
      <label
        className="text-[16px] leading-[24px] text-[#252525]"
        htmlFor="phone"
      >
        Phone Number *
      </label>
      <input
        type="tel"
        placeholder="Enter Phone Number"
        className="duration-300 transition-all ease-in-out text-[#252525] rounded-[2px] outline-none border-solid border-[#252525] border-[1px] p-[0.7em]"
      />

<ValidationError
                  prefix="phone-number"
                  field="phone-number"
                  errors={state.errors}
                />
      
    </div>

    <div className="form-section flex flex-col gap-[0.3em] mb-[1em]">
      <label
        className="text-[16px] leading-[24px] text-[#252525]"
        htmlFor="Job Type"
      >
        Select an Investment Plan *
      </label>
      <div className="select-wrapper w-full border-solid border-[1px] border-[#808080] rounded-[2px] transition-all duration-400 ease-in-out">
        <select
          name="location"
          id="location"
          className="text-[#252525] text-[14px] leading-[24px] w-full h-full border-none  outline-none"
        >
          <option value="select-country">Select Country</option>
          <option value="DZ">Algeria</option>
          <option value="AO">Angola</option>
          <option value="BJ">Benin</option>
          <option value="BW">Botswana</option>
          <option value="BF">Burkina Faso</option>
          <option value="BI">Burundi</option>
          <option value="CM">Cameroon</option>
          <option value="CV">Cape Verde</option>
          <option value="CF">Central African Republic</option>
          <option value="TD">Chad</option>
          <option value="KM">Comoros</option>
          <option value="CG">Congo</option>
          <option value="CD">
            Congo, Democratic Republic of the Congo
          </option>
          <option value="CI">Cote D'Ivoire</option>
          <option value="DJ">Djibouti</option>
          <option value="EG">Egypt</option>
          <option value="GQ">Equatorial Guinea</option>
          <option value="ER">Eritrea</option>
          <option value="ET">Ethiopia</option>
          <option value="GA">Gabon</option>
          <option value="GM">Gambia</option>
          <option value="GH">Ghana</option>
          <option value="GN">Guinea</option>
          <option value="GW">Guinea-Bissau</option>
          <option value="KE">Kenya</option>
          <option value="LS">Lesotho</option>
          <option value="LR">Liberia</option>
          <option value="LY">Libyan Arab Jamahiriya</option>
          <option value="MG">Madagascar</option>
          <option value="MW">Malawi</option>
          <option value="ML">Mali</option>
          <option value="MR">Mauritania</option>
          <option value="MU">Mauritius</option>
          <option value="YT">Mayotte</option>
          <option value="MA">Morocco</option>
          <option value="MZ">Mozambique</option>
          <option value="NA">Namibia</option>
          <option value="NE">Niger</option>
          <option value="NG">Nigeria</option>
          <option value="RE">Reunion</option>
          <option value="RW">Rwanda</option>
          <option value="SH">Saint Helena</option>
          <option value="ST">Sao Tome and Principe</option>
          <option value="SN">Senegal</option>
          <option value="SC">Seychelles</option>
          <option value="SL">Sierra Leone</option>
          <option value="SO">Somalia</option>
          <option value="ZA">South Africa</option>
          <option value="SS">South Sudan</option>
          <option value="SD">Sudan</option>
          <option value="SZ">Swaziland</option>
          <option value="TZ">Tanzania, United Republic of</option>
          <option value="TG">Togo</option>
          <option value="TN">Tunisia</option>
          <option value="UG">Uganda</option>
          <option value="EH">Western Sahara</option>
          <option value="ZM">Zambia</option>
          <option value="ZW">Zimbabwe</option>
        </select>
      </div>
      <ValidationError
                  prefix="location"
                  field="location"
                  errors={state.errors}
                />
    </div>

    <button className="early-career-btn flex items-center border w-[131px] hover:w-[189px] hover:bg-[#3db2ed] cursor-pointer flex mt-[1.5em] py-[15px] px-[30px] transition-all duration-500 ease-in-out bg-[#0177B2] h-[62px] text-[24px] leading-[32px] text-[white]">
      <span className="mr-[20px]">Submit</span>
      <span>
        <img className="btn-img ml-[10px]" src={Vector} alt="" />
      </span>
    </button>
  </form>
</fieldset>

}