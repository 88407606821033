import React from "react";
import { useInView } from "react-intersection-observer";
import "./Animation.scss"; // Create a CSS file for styling animations

const Scroll = ({ children, animationDirection, possibleClass }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation only once when it enters the viewport
  });

  return (
    <div
      ref={ref}
      className={` scroll-animated-div
       ${
         inView
           ? `animate-${animationDirection} ${possibleClass}`
           : `${
               animationDirection === "left"
                 ? "translate-x-[-100px]  "
                 : "translate-x-[100px]  "
             }`
       }`}
    >
      {children}
    </div>
  );
};

export default Scroll;
