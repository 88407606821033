import React from "react";
import Scroll from "../../Reusables/Animation/Scroll";
import "./index.scss";
const Invoice = () => {
  const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className="h-[60vh] relative top-[65px] sm:h-screen w-full  bg-[black]">
          <img
            src={test.Banner}
            alt=""
            className=" h-full opacity-70 w-full  object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Inventory Discounting Note
            </h2>
          </div>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] career-container py-[2em] px-[2em] sm:px-[4em] overflow-hidden">
        <Scroll
          animationDirection={"left"}
          possibleClass="career-header text-[#001D45] mt-[2em] pb-[30px]"
        >
          <h2 className="text-[40px] leading-[48px] ">
            Invoice Discounting Note
          </h2>
          <p className="text-[16px] leading-[24px] pb-[30px] text-justify">
            Invoice discounting allows a business to leverage the value of its
            account receivables (to access financing for the business. The
            invoice will be pledged as the collateral.
          </p>
          <p>
            The amount issued on this facility is less than the total amount of
            outstanding receivables.
          </p>
        </Scroll>

        <Scroll
          animationDirection={"right"}
          possibleClass="product-main relative w-full h-full"
        >
          <table className="w-full border-collapse">
            <tr className="border">
              <th>SECTOR</th>
              <th colSpan={2}>Agnostic, B2B; B2C</th>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                CLUSTER OF INTEREST
              </td>
              <td
                colSpan={2}
                className="p-[8px] border-solid border-[1px] border-[#ddd]"
              >
                Manufacturing, Logistics, AGRO Services, Telecoms, Construction,
                Service Companies, Food Beverage production, Pharmaceuticals
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                TRANSACTION SIZE
              </td>
              <td
                colSpan={2}
                className="p-[8px] border-solid border-[1px] border-[#ddd]"
              >
                5-20 million Naira
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                PRICING
              </td>
              <td
                colSpan={2}
                className="p-[8px] border-solid border-[1px] border-[#ddd]"
              >
                Minimum 5% IRR
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                TENOR
              </td>
              <td
                colSpan={2}
                lassName="p-[8px] border-solid border-[1px] border-[#ddd]"
              >
                90 days per cycle
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                PROCESSING TIME
              </td>
              <td
                colSpan={2}
                className="p-[8px] border-solid border-[1px] border-[#ddd]"
              >
                7-14 days
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                REPAYMENT SOURCE
              </td>
              <td
                colSpan={2}
                lassName="p-[8px] border-solid border-[1px] border-[#ddd]"
              >
                Operational Cashflow from financed production cycle of the
                business (Pledged receivables for Grade A customers)
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                REQUIREMENTS
              </td>
              <li>Cashflowcollate ralization Domiciliation</li>
              <li>Verifiable Invoice from credible customer</li>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                <li>Sales History forecast</li>
                <li>
                  -Min. 35% gross margin on the financed cycle
                  <p className="ml-[20px]">1. Third party guarantee</p>
                  <p className="ml-[20px]">2. Additional collateral</p>
                </li>
              </td>
            </tr>
          </table>
        </Scroll>
      </div>
    </div>
  );
};

export default Invoice;
