import React, {useEffect} from "react";
import MainHeader from "../Reusables/Header/MainHeader";
import Footer from "../Reusables/Footer/Footer";
import Serv from "./components/Serv";
import Enquiry from "../Reusables/Enquiry/Enquiry";
const Service = () => {
  useEffect(() => {
    document.title = "Our Services";
  }, []);
  return (
    <div>
      <MainHeader />
      <Serv/>
      <Enquiry/>
      <Footer />
    </div>
  );
};

export default Service;
