import React,{useEffect} from 'react'
import SCMHeader from '../Reusables/Header/SCMHeader'
import Leverage from './components/Leverage'
import Footer from '../Reusables/Footer/Footer'
const LeverageNote = () => {
  useEffect(() => {
    document.title = "Leaverage Note";
  }, []);
  return (
    <div>
        <SCMHeader/>
        <Leverage/>
        <Footer/>
    </div>
  )
}

export default LeverageNote