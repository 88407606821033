import React from "react";
import { Link } from "react-router-dom";
import Back from "../../assets/back.svg";
import Career from "../../assets/Vector.svg";
import Scroll from "../../Reusables/Animation/Scroll";
import CareererForm from '../../Reusables/career_form';

const Early = () => {
  const goBack = () => {
    window.history.back();
  };

  return (
    <div>
      <div className="early-career-container py-[4em] px-[2em]  sm:px-[10em] block sm:flex justify-between gap-[2em] overflow-hidden">
        <Scroll
          animationDirection={"right"}
          possibleClass="early-career-content md:w-[55%] sm:w-[70%] ml-0 w-full sm:ml-[3em] mt-[2em]"
        >
          <Link
            onClick={goBack}
            className="back flex items-center mb-[1.5em] relative z-[40] text-[16px] leading-[24px] text-[#252525]"
          >
            <img src={Back} alt="" className="mr-[1em] w-[15px]" />
            <span>Back</span>
          </Link>

          <div className="early-career-text text-[#252525]">
            <h3 className="text-[24px] leading-[32px] font-[800]">
              Experienced Hire roles Careers Application Page
            </h3>
            <p className="my-[2em] mx-0 text-[14px] leading-[24px] text-justify">
              Welcome to our Experience Careers application page! We are excited
              that you are considering joining our team of finance experts.
              Please submit your application below. We look forward to knowing
              you better
            </p>
          </div>

     <CareererForm/>
              </Scroll>
      </div>

      <div className="early-career-img">
        <img src={Career} alt="" />
      </div>
    </div>
  );
};

export default Early;
