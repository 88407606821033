import React from "react";
import Scroll from "../../Reusables/Animation/Scroll";
import "./index.scss";
const WorkCapital = () => {
  const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className="h-[60vh] relative top-[65px] sm:h-screen w-full  bg-[black]">
          <img
            src={test.Img244}
            alt=""
            className=" h-full opacity-70 w-full  object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Working Capital Financing(WCF) Note
            </h2>
          </div>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] career-container py-[2em] md:py-[5em] px-[2em] sm:px-[4em] overflow-hidden">
        <Scroll
          animationDirection={"left"}
          className="career-header text-[#001D45] mt-[2em] pb-[30px]"
        >
          <h2 className="text-[40px] leading-[48px] ">
            Working Capital Financing(WCF) Note
          </h2>
          <p className="text-[16px] leading-[24px] pb-[30px]">
            Our WCF offers business owners a breather in financing the variable
            part of working capital, to only cover liquidity for production
            expansion and execution for targeted customers.
          </p>
          <p>
            This is to be linked directly to revenue and approved invoices from
            qualified customers and can be financed with senior debt or
            mezzanine debt.
          </p>
          <p>
            WCF could be structured as a line of credit and secured by the
            cashflow of the business at the topline with repayment recognized as
            a first line charge item, on the cashflow waterfall.
          </p>
        </Scroll>

        <Scroll
          animationDirection={"right"}
          possibleClass="product-main relative w-full h-full"
        >
          <table className="w-full border-collapse">
            <tr>
              <th>SECTOR</th>
              <th>Agnostic, B2B;B2C</th>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                CLUSTER OF INTEREST
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                Clinics,Retail Stores, Food Manufacturing R etail, FMCG
                Distributors, Mechanical Electrical Parts retailers etc
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                TRANSACTION SIZE
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                5-50 million Naira
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                PRICING
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                Minimum 5% IRR
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                TENOR
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                90 days per cycle
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                PROCESSING TIME
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                7-14 days
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                REPAYMENT SOURCE
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                Operational Cashflow from financed production cycle of the
                business (Pledged receivables for Grade A customers)
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                REQUIREMENTS
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                <li>Cashflow of the business</li>
                <li>Schedule of pledged receivables</li>
                <li>Cashflowcollate ralization Domiciliation</li>
                <li>
                  Min. 35% gross margin on the financed cycle
                  <p className="ml-[20px]">
                    1. Other operational cashflow of the business
                  </p>
                  <p className="ml-[20px]">2. Third party guarantee</p>
                </li>
              </td>
            </tr>
          </table>
        </Scroll>
      </div>
    </div>
  );
};

export default WorkCapital;
