import React,{useEffect} from 'react'
import MainHeader from '../Reusables/Header/MainHeader'
import Enquiry from '../Reusables/Enquiry/Enquiry'
import Footer from '../Reusables/Footer/Footer'
import Career from './components/Career'
const Careers = () => {
  useEffect(() => {
    document.title = "Career";
  }, []);
  return (
    <div>
        <MainHeader/>
        <Career/>
        <Enquiry/>
        <Footer/>
    </div>
  )
}

export default Careers