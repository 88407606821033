import React,{useEffect} from 'react'
import SCMHeader from '../Reusables/Header/SCMHeader'
import Invent from './components/Invent'
import Footer from '../Reusables/Footer/Footer'

const Inventory = () => {
  useEffect(() => {
    document.title = "Inventory";
  }, []);
  return (
    <div>
        <SCMHeader/>
        <Invent/>
        <Footer/>
        </div>
  )
}

export default Inventory