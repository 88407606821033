import React from "react";
import Scroll from "../../Reusables/Animation/Scroll";
import Ser from "../../assets/img/our-services.jpeg";
const Serv = () => {
  // const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div className="">
      <div className="relative h-full">
        <div className=" h-[60vh] relative top-[65px] sm:h-screen w-full  bg-[black]">
          <img
            src={Ser}
            alt=""
            className=" h-full opacity-70 w-full object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px]">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Our Services
            </h2>
          </div>
        </div>
      </div>
      <div className=" bg-[#5DDDFF] py-[3em]">
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px]  py-[3em]">
          <h2 className="text-[25px] md:text-[35px] lg:text-[60px] text-center">
            What We Offer
          </h2>
          <div className=" lower-grp flex bg-[#5DDDFF] flex-col sm:flex-row px-[2em] sm:px-[4em] overflow-hidden w-full justify-between mt-[2em] overflow-hidden w-full">
            <Scroll
              animationDirection={"left"}
              possibleClass=" w-full sm:w-[47%] "
            >
              <div className="flex items-center">
                <h3 className="text-[24px] text-[#001D45] leading-[32px] mb-[0.5em]">
                  Investment Management
                </h3>

                <div className="overlay-container w-[40px] h-[40px] relative ml-6">
                  <div className="mini-overlay-1 absolute bg-[#001d45e6] w-[27px] h-[27px] bottom-0 left-0"></div>
                  <div className="mini-overlay-2 absolute bg-[#001d45e6] w-[27px] h-[27px] top-0 right-0"></div>
                </div>
              </div>
              <div className="details flex justify-center flex-col mt-[2em] text-[18px] ">
                <p className="mb-[10px] text-justify">
                  With a focus on consistent and long-term value creation, you
                  can trust that your investments are safe and secured. We give
                  priority to the portfolios of our client’s financial health by
                  engaging in global best practices for managing risks.
                </p>
                <p className="mb-[10px] text-justify">
                  Our experienced fund managers and investment experts have a
                  proven track record of delivering exceptional results for our
                  clients. Using our innovative strategies and incisive
                  understanding of market dynamics, we provide our clients with
                  the best form of expertise, transparency, and accountability.
                </p>
                <p className="mb-[10px] text-justify">
                  We have carved a niche for ourselves in corporate financial
                  prudence by consciously aligning our clients' risk appetites
                  with appropriate investment classes, cherry-picked from a
                  blend of different procedures of investments. With this, our
                  clients have received unexpected but satisfactory returns
                  which have consistently outperformed the market.
                </p>
              </div>
            </Scroll>

            <Scroll
              animationDirection="right"
              possibleClass="text-content w-full sm:w-[47%]"
            >
              <div className="text-subtitle flex items-center">
                <h3 className="text-[24px] text-[#001D45] leading-[32px] mb-[0.5em]">
                  High Yield Private Debt Funds
                </h3>
                <div className="overlay-container w-[40px] h-[40px] relative ml-6">
                  <div className="mini-overlay-1 absolute bg-[#001d45e6] w-[27px] h-[27px] bottom-0 left-0"></div>
                  <div className="mini-overlay-2 absolute bg-[#001d45e6] w-[27px] h-[27px] top-0 right-0"></div>
                </div>
              </div>
              <div className="details flex justify-center flex-col mt-[2em] text-[18px]">
                <p className="mb-[10px] text-justify">
                  We specialise in providing investors access to high-quality,
                  investment opportunities that generate reliable and alluring
                  returns while offering protection against negative
                  consequences through our high yield Private Debt Funds.
                </p>
                <p className="mb-[10px] text-justify">
                  Our investment professionals employ a disciplined and
                  research-driven approach to investment using pure instruments.
                  They do this by emphasising credit quality and asset coverage.
                  Our company also gives attention to income generation,
                  interest and principal repayment, and ensures that clients
                  receive consistent returns.
                </p>
                <p className="mb-[10px] text-justify">
                  Our High Yield Private Debt Funds provide our clients with
                  wide options and flexibility, compared to traditional asset
                  classes in the public market. We advise you not to opt for
                  mediocre returns, when you can simply invest with us and
                  experience superior returns.
                </p>
              </div>
            </Scroll>
          </div>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px]  py-[3em] ">
        <div className="lower-grp flex flex-col sm:flex-row justify-between mt-[2em] px-[2em] sm:px-[4em] overflow-hidden w-full">
          <Scroll
            animationDirection={"left"}
            possibleClass="text-content w-full sm:w-[47%]"
          >
            <div className="text-subtitle flex items-center">
              <h3 className="text-[#001D45] text-[24px] leading-[32px] mb-[0.5em]">
                Structured Financing
              </h3>
              <div className="overlay-container w-[40px] h-[40px] relative ml-6">
                <div className="mini-overlay-1 absolute bg-[#001d45e6] w-[27px] h-[27px] bottom-0 left-0"></div>
                <div className="mini-overlay-2 absolute bg-[#001d45e6] w-[27px] h-[27px] top-0 right-0"></div>
              </div>
            </div>
            <div className="details flex justify-center flex-col mt-[2em] text-[18px]">
              <p className="mb-[10px] text-justify">
                At our company, we provide comprehensive transaction structuring
                services. This ranges from initial analysis of business
                objectives to offering input into major agreements and clauses.
                We work closely with our clients- in the private and public
                sectors, to ensure they are in a good position to make informed
                and strategic decisions.
              </p>
              <p className="mb-[10px] text-justify">
                Our team consists of individuals that have extensive knowledge
                and experience in structuring and carrying out financial deals
                across various sectors. We equally specialise in creating
                customised debt, equity, and hybrid financing solutions tailored
                to meet the needs of each client. Our solutions are designed to
                reduce risk, maximise flexibility, and optimise capital
                structure, thereby making our clients achieve their financial
                goals with confidence.
              </p>
              {/* <p className="mb-[10px] text-justify">
                We specialize in creating customized debt, equity, and hybrid
                financing solutions that are tailored to meet the unique needs
                of each client. Our transaction structuring services is
                comprehensive, from the initial analysis of business objectives
                to offering input into major agreements and clauses. We work
                closely with our clients to ensure that they are fully informed
                and empowered to make strategic decisions every step of the way.
              </p>
              <p className="mb-[10px] text-justify">
                Our solutions are designed to reduce risk, maximize flexibility,
                and optimize capital structure while ensuring that our clients
                can achieve their financial goals with confidence. Our approach
                is oftentimes buy side, because we don’t jus structure, we also
                fund from our balance sheet.
              </p> */}
            </div>
          </Scroll>

          <Scroll
            animationDirection={"right"}
            possibleClass={"text-content w-full sm:w-[47%]"}
          >
            <div className="flex items-center">
              <h3 className="text-[28px] leading-[36px] mb-[0.5em]">
                Brokerage and Arrangement Services
              </h3>
              <div className="overlay-container w-[40px] h-[40px] relative ml-6">
                <div className="mini-overlay-1 absolute bg-[#001d45e6] w-[27px] h-[27px] bottom-0 left-0"></div>
                <div className="mini-overlay-2 absolute bg-[#001d45e6] w-[27px] h-[27px] top-0 right-0"></div>
              </div>
            </div>
            <div className="mt-[2em] text-[18px] flex justify-center flex-col">
              <p className="mb-[10px] text-justify">
                We offer innovative and dynamic brokerage and arrangement
                services across various financing needs designed to help you
                achieve your financing and investment goals. Our expert
                financial advisors bring a wealth of experience and knowledge to
                the table, providing our clients with expert negotiation skills,
                and making sure we secure the best deal possible.
              </p>
              <p className="mb-[10px] text-justify">
                Looking to invest in equity, debts, opportunistic deals or
                alternative investments? We have the expertise and resources to
                help our clients succeed. With our cutting-edge technology and
                market insights, we provide clients with the latest information
                to make informed investment decisions.
              </p>
            </div>
          </Scroll>
        </div>
      </div>
      <div className="bg-[#5DDDFF]">
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px]  py-[3em] ">
          <div className="lower-grp flex flex-col sm:flex-row justify-between mt-[2em] overflow-hidden px-[2em] sm:px-[4em] overflow-hidden w-full">
            <Scroll
              animationDirection={"left"}
              possibleClass={"text-content w-full sm:w-[47%]"}
            >
              <div className="flex items-center">
                <h3 className="text-[28px] leading-[36px] mb-[0.5em]">
                  Mergers & Acquisitions
                </h3>
                <div className="overlay-container w-[40px] h-[40px] relative ml-6">
                  <div className="mini-overlay-1 absolute bg-[#001d45e6] w-[27px] h-[27px] bottom-0 left-0"></div>
                  <div className="mini-overlay-2 absolute bg-[#001d45e6] w-[27px] h-[27px] top-0 right-0"></div>
                </div>
              </div>
              <div className="mt-[2em] text-[18px] flex justify-center flex-col">
                <p className="mb-[10px] text-justify">
                  Benefitting from the diverse background of our team, Sthenic
                  finance and advisory has experts who have a deep understanding
                  of the intricacies involved in structuring, negotiating, and
                  executing successful mergers and acquisitions to assist
                  businesses looking to expand, divest, or take advantages of
                  adjacent opportunities.
                </p>
                <p className="mb-[10px] text-justify">
                  We help our customers evaluate strategic choices and reduce
                  complexity in transactions such as leveraged buy-outs (LBOs),
                  joint ventures, divestitures, and split-offs. Whether you’re
                  looking to acquire a competitor, merge with a complementary
                  business, or sell your company, we’re here to help. Our
                  approach is simplistic – value for money for all parties at
                  the most competitive and smartest rate in time and price.
                </p>
              </div>
            </Scroll>
            <Scroll
              animationDirection={"right"}
              possibleClass={"text-content w-full sm:w-[47%]"}
            >
              <div className="flex items-center">
                <h3 className="text-[28px] leading-[36px] mb-[0.5em]">
                  Equity & Debt Capital Raise
                </h3>
                <div className="overlay-container w-[40px] h-[40px] relative ml-6">
                  <div className="mini-overlay-1 absolute bg-[#001d45e6] w-[27px] h-[27px] bottom-0 left-0"></div>
                  <div className="mini-overlay-2 absolute bg-[#001d45e6] w-[27px] h-[27px] top-0 right-0"></div>
                </div>
              </div>
              <div className="mt-[2em] text-[18px] flex justify-center flex-col">
                <p className="mb-[10px] text-justify">
                  We offer customized solutions to help you raise capital –
                  equity and/or debt, either in the primary or secondary
                  markets, with optimal capital structure and efficient cap
                  table in mind.
                </p>
                <p className="mb-[10px] text-justify">
                  With our wealth of experience working with a wide range of
                  businesses, from start-ups to established enterprises, we
                  understand the unique challenges and opportunities that
                  businesses face in raising capital. Our approach is to work
                  closely with you to craft a tailored approach that fits your
                  capital needs. Whether you're seeking to fund a new project,
                  expand your operations, or acquire new assets, we'll work with
                  you to develop a strategy that aligns with your objectives and
                  helps you achieve success.
                </p>
              </div>
            </Scroll>
          </div>
        </div>
      </div>
      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px]  py-[3em] ">
        <div className="lower-grp flex flex-col sm:flex-row justify-between mt-[2em] overflow-hidden px-[2em] sm:px-[4em] overflow-hidden w-full">
          <Scroll
            animationDirection={"left"}
            possibleClass={"text-content w-full sm:w-[47%]"}
          >
            <div className="flex items-center">
              <h3 className="text-[28px] leading-[36px] mb-[0.5em]">
                Financial Advisory and Finance Service Advisory
              </h3>
              <div className="overlay-container w-[40px] h-[40px] relative ml-6">
                <div className="mini-overlay-1 absolute bg-[#001d45e6] w-[27px] h-[27px] bottom-0 left-0"></div>
                <div className="mini-overlay-2 absolute bg-[#001d45e6] w-[27px] h-[27px] top-0 right-0"></div>
              </div>
            </div>
            <div className="mt-[2em] text-[18px] flex justify-center flex-col">
              <p className="mb-[10px] text-justify">
                Our financial advisory service provides specialised professional
                advice that prepares the clients and their businesses for
                growth, capital raise and or an efficient corporate and
                operational structure and strategy for investment readiness. Our
                value creation approach implies that we work with the business
                from the long term positioning and vision to systematize the day
                to day activities in the present.
              </p>
              <p className="mb-[10px] text-justify">
                The Finance Service Advisory segment operates on a retainer and
                take over the corporate Asset- Liabilities Management (ALM)
                roles, outsourced, with a view to craft the story of the
                business, pragmatically for a financially sustainable future as
                desired by the promoters. Our approach is to take charge of your
                financial and corporate growth narratives and continue to work
                with you to shape it as as you approach various financing
                milestones.
              </p>
            </div>
          </Scroll>
          <Scroll
            animationDirection={"right"}
            possibleClass={"text-content w-full sm:w-[47%]"}
          >
            <div className="flex items-center">
              <h3 className="text-[28px] leading-[36px] mb-[0.5em]">
                Project Finance
              </h3>
              <div className="overlay-container w-[40px] h-[40px] relative ml-6">
                <div className="mini-overlay-1 absolute bg-[#001d45e6] w-[27px] h-[27px] bottom-0 left-0"></div>
                <div className="mini-overlay-2 absolute bg-[#001d45e6] w-[27px] h-[27px] top-0 right-0"></div>
              </div>
            </div>
            <div className="mt-[2em] text-[18px] flex justify-center flex-col">
              <p className="mb-[10px] text-justify">
                As a forward-thinking firm, we understand that securing funding
                for ambitious projects can be challenging, especially in
                emerging markets. That's why we stay up to date on the latest
                changes in the local project finance landscape to create
                transaction structures that reduce inherent risks.
              </p>
              <p className="mb-[10px] text-justify">
                Over the years, we have successfully helped businesses across
                various industries secure financing for their project
                initiatives using a project finance note. Our flexible terms and
                competitive rates make it easier for businesses to access the
                funding they need to take on new long-term ventures or expand
                existing ones.
              </p>
              <p className="mb-[10px] text-justify">
                If you're a business owner seeking to fund ambitious projects,
                Sthenic’s project finance service may be a powerful tool for
                you. We craft strategies that are unique to our clients to help
                them meet their specific project needs while providing guidance
                and support throughout the process.
              </p>
            </div>
          </Scroll>
        </div>
      </div>
    </div>
  );
};

export default Serv;
