import React,{useEffect} from "react";
import SCMHeader from "../Reusables/Header/SCMHeader";
import Project from "./components/Project";
import Footer from "../Reusables/Footer/Footer";
const ProjectFinance = () => {
  useEffect(() => {
    document.title = "Project Finance";
  }, []);
  return (
    <div>
      <SCMHeader />
      <Project />
      <Footer />
    </div>
  );
};

export default ProjectFinance;
