import React from "react";
import Finance from "../../assets/Project-Finance.jpeg";
import Broker from "../../assets/broker.jpeg";
import Foreign from "../../assets/foreign.jpeg";
import Scroll from "../../Reusables/Animation/Scroll";
import "./index.scss";
const Approac = () => {
  return (
    <div>
      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] sm:px-[4em] py-[2em] overflow-hidden">
        <Scroll animationDirection={"left"} possibleClass="py-8 sect">
          <h2 className="text-[25px] md:text-[35px] lg:text-[60px] text-center text-[#001D45] ">
            Our Approach
          </h2>
          <p className="mt-[20px] text-justify">
            Our approach to capital management is geared towards delivering
            exceptional returns on investment while mitigating risks. Our
            company has a well-curated three-stage approach that ensures our
            clients’ investments are properly managed, scrutinised, and executed
            with a solid risk management strategy.
          </p>
        </Scroll>
      </div>
      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] sm:px-[4em] py-[2em] overflow-hidden">
        <div className="flex justify-between flex-col-reverse md:flex-row  my-[30px] ">
          <Scroll
            animationDirection={"left"}
            possibleClass="bg-white mb-[30px] md:mb-0 w-full md:w-[45%]"
          >
            <h4 className="text-[28px] text-[#001D45] font-[600]">
              Targeted and Tailored Deal Structure
            </h4>
            <p className="mb-[10px] text-justify">
              Our investment team focuses on a targeted and tailored deal
              structure that aligns with the overall portfolio strategy. We pay
              attention to beneficiary's assets, financials, market, and
              associated risks involved in investment. We also conduct a
              three-step Know-Your-Client (KYC) verification process, consisting
              of the promoter's pedigree, company’s pedigree, and
              ecosystem’s/guarantor's pedigree. Our process provides support in
              developing an adaptable financial reporting process, business
              development, risk management and compliance systems.
            </p>
          </Scroll>
          <Scroll
            animationDirection={"right"}
            possibleClass="w-full md:w-[45%]"
          >
            <img src={Finance} alt="" />
          </Scroll>
        </div>
      </div>
      <div className="bg-[#5DDDFF]">
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] sm:px-[4em] py-[2em] overflow-hidden">
          <div className="flex justify-between flex-col md:flex-row my-[30px] sect">
            <Scroll
              animationDirection={"left"}
              possibleClass="w-full md:w-[45%]"
            >
              <img src={Broker} alt="" />
            </Scroll>
            <Scroll
              animationDirection={"right"}
              possibleClass=" mb-[30px] md:mb-0  w-full md:w-[45%]"
            >
              <h4 className="text-[28px] text-[#001D45] font-[600]">
                Continuous Monitoring and Review
              </h4>
              <p className="text-justify">
                At Sthenic, we do not stop at the execution of deal. Our team of
                selected professionals, led by a Subject Matter Expert (SME)
                with proven knowledge of the sector, owns and monitors cash flow
                performance of any investment. This continuous monitoring and
                review of investments are usually done in order to ensure
                optimal performance based on pre-agreed terms.
              </p>
            </Scroll>
          </div>
        </div>
      </div>
      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] sm:px-[4em] py-[2em] overflow-hidden">
        <div className="flex justify-between flex-col-reverse md:flex-row my-[30px] sect">
          <Scroll
            animationDirection={"left"}
            possibleClass="bg-white mb-[30px] md:mb-0 w-full md:w-[45%]"
          >
            <h4 className="text-[28px] text-[#001D45] font-[600]">
              Outstanding Risk Management
            </h4>
            <p className="text-justify">
              We take risk management seriously at Sthenic Capital Management
              (SCM). Our company embeds risk considerations in every aspect of
              the investment process, from origination through underwriting to
              exit. In this, we seek to ensure early detection of potential
              downside risks and guarantee expected ROI.
            </p>
          </Scroll>
          <Scroll
            animationDirection={"right"}
            possibleClass="w-full md:w-[45%]"
          >
            <img src={Foreign} alt="" />
          </Scroll>
        </div>
      </div>
    </div>
  );
};

export default Approac;
