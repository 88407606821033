// import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import "./App.scss";
import { Routes, Route, useLocation } from "react-router-dom";
import LandingPage from "./MainPage/LandingPage";
import Service from "./MainPage/Service";
import History from "./MainPage/History";
import Insights from "./MainPage/Insights";
import Careers from "./MainPage/Careers";
import ContactUs1 from "./MainPage/ContactUs1";
import ContactUs2 from "./MainPage/ContactUs2";
import CareerHire from "./MainPage/CareerHire";
import EarlyCareer from "./MainPage/EarlyCareer";
import AboutScm from "./SCM/AboutScm";
import Funds from "./SCM/Funds";
import Investors from "./SCM/Investors";
import Investees from "./SCM/Investees";
import OurProducts from "./SCM/OurProducts";
import AssetBackedNote from "./SCM/AssetBackedNote";
import Ecosystem from "./SCM/Ecosystem";
import VentureCapital from "./SCM/VentureCapital";
import WorkingCapital from "./SCM/WorkingCapital";
import Inventory from "./SCM/Inventory";
import Receivable from "./SCM/Receivable";
import InvoiceDiscount from "./SCM/InvoiceDiscount";
import ExportFinance from "./SCM/ExportFinance";
import LeverageNote from "./SCM/LeverageNote";
import ProjectFinance from "./SCM/ProjectFinance";
import LendingFinance from "./SCM/LendingFinance";
import PurchaseFinance from "./SCM/PurchaseFinance";
import Portfolios from "./SCM/Portfolios";
import SCMCareer from "./SCM/SCMCareer";
import SCMEarlyCareer from "./SCM/SCMEarlyCareer";
import SCMCareerHire from "./SCM/SCMCareerHire";
import SCMContact1 from "./SCM/SCMContact1";
import SFAAbout from "./SFA/SFAAbout";
import Capabilities from "./SFA/Capabilities";
import Services from "./SFA/Services";
import DealBook from "./SFA/DealBook";
import SFACareer from "./SFA/SFACareer";
import Potentials from "./SFA/Potentials";
import SFAInvestors from "./SFA/SFAInvestors";
import SFAContact from "./SFA/SFAContact";

// images to be sent to localStorage for fast display
import Carer from "./assets/Carer.jpeg";
import Location from "./assets/location.jpeg";
import Histor from "./assets/our-history.jpeg";
import Insigh from "./assets/inshith.jpeg";
import Work from "./assets/imgservice.jpeg";
import City from "./assets/imghome.jpeg";
import Scm from "./assets/scm.jpeg";
import Farm from "./assets/166.jpeg";
import Banner from "./assets/88.jpeg";
import Fund from "./assets/6.jpeg";
import Grass from "./assets/244.jpeg";
import Sunshine from "./assets/266.jpeg";
import Sthentic from "./assets/sthenic.jpeg";
import Valley from "./assets/22.jpeg";
import Island from "./assets/island.jpeg";
import Img88 from "./assets/88.jpeg";
import Img110 from "./assets/110.jpeg";
import Img211 from "./assets/211.jpeg";
import Img144 from "./assets/144.jpeg";
import Img244 from "./assets/244.jpeg";
import Img155 from "./assets/155.jpeg";
import Img44 from "./assets/44.jpeg";
import Img277 from "./assets/277.jpeg";
import Img177 from "./assets/177.jpeg";
import Img200 from "./assets/200.jpeg";
import Img266 from "./assets/266.jpeg";
import Img6 from "./assets/6.jpeg";
import Img22 from "./assets/22.jpeg";
import Img233 from "./assets/233.jpeg";
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const App = () => {
  const images = {
    Img266,
    Img6,
    Img233,
    Img22,
    Img200,
    Img177,
    Img110,
    Img277,
    Img44,
    Img155,
    Img244,
    Img144,
    Img211,
    Img88,
    Carer,
    Location,
    Histor,
    Insigh,
    Work,
    City,
    Scm,
    Farm,
    Banner,
    Fund,
    Grass,
    Sunshine,
    Sthentic,
    Valley,
    Island,
  };

  localStorage.setItem("imgs", JSON.stringify(images));
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        {/* Main Pages */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/service" element={<Service />} />
        <Route path="/history" element={<History />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact1" element={<ContactUs1 />} />
        <Route path="/contact2" element={<ContactUs2 />} />
        <Route path="/career-hire" element={<CareerHire />} />
        <Route path="/early-career" element={<EarlyCareer />} />

        {/* Scm pages */}
        <Route path="/scm/about-us" element={<AboutScm />} />
        <Route path="/scm/funds" element={<Funds />} />
        <Route path="/scm/investor" element={<Investors />} />
        <Route path="/scm/investee" element={<Investees />} />
        <Route path="/scm/products" element={<OurProducts />} />
        <Route path="/scm/asset-backed-note" element={<AssetBackedNote />} />
        <Route path="/scm/eco-system-financing-note" element={<Ecosystem />} />
        <Route
          path="/scm/venture-capital-financing"
          element={<VentureCapital />}
        />
        <Route
          path="/scm/working-capital-financing"
          element={<WorkingCapital />}
        />
        <Route path="/scm/inventory-financing-note" element={<Inventory />} />
        <Route path="/scm/receivable-line-of-credit" element={<Receivable />} />
        <Route
          path="/scm/invoice-discounting-note"
          element={<InvoiceDiscount />}
        />
        <Route
          path="/scm/export-and-trade-financing"
          element={<ExportFinance />}
        />
        <Route path="/scm/leverage-buy-out" element={<LeverageNote />} />
        <Route
          path="/scm/project-financing-note"
          element={<ProjectFinance />}
        />
        <Route path="/scm/on-lending-financing" element={<LendingFinance />} />
        <Route
          path="/scm/purchase-order-financing"
          element={<PurchaseFinance />}
        />
        <Route path="/scm/our-impact" element={<Portfolios />} />
        <Route path="/scm/careers" element={<SCMCareer />} />
        <Route path="/scm/early-career" element={<SCMEarlyCareer />} />
        <Route path="/scm/career-hire" element={<SCMCareerHire />} />
        <Route path="/scm/contact-us" element={<SCMContact1 />} />

        {/* SFA Pages */}
        <Route path="/sfa/about-us" element={<SFAAbout />} />
        <Route path="/sfa/capabilities" element={<Capabilities />} />
        <Route path="/sfa/services" element={<Services />} />
        <Route path="/sfa/dealbook" element={<DealBook />} />
        <Route path="/sfa/careers" element={<SFACareer />} />
        <Route path="/sfa/potential" element={<Potentials />} />
        <Route path="/sfa/investor" element={<SFAInvestors />} />
        <Route path="/sfa/contact-us" element={<SFAContact />} />
      </Routes>
    </div>
  );
};

export default App;
