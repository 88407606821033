import React from "react";
import { Link } from "react-router-dom";
import Working from "../../assets/working-capital-financing.webp";
import Receive from "../../assets/receivable.jpeg";
import Target from "../../assets/target.png";
import Asset from "../../assets/assest-back-note.png";
import Inventory from "../../assets/Inventory.jpeg";
import Ecosystem from "../../assets/Ecosystems.jpeg";
import Invoice from "../../assets/invoice-discounting.jpeg";
import VC from "../../assets/vc.jpeg";
import Export from "../../assets/export-trade.jpeg";
import LBO from "../../assets/LBO-Financing.jpeg";
import Project from "../../assets/project-financing.jpeg";
import Lend from "../../assets/onlending.webp";
import Purchase from "../../assets/Purchase-Order-Financing.jpeg";
import Scroll from "../../Reusables/Animation/Scroll";
// import "./index.scss";
import Prod from "../../assets/img/our-product.jpeg";
const Products = () => {
  // const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className="h-[60vh] relative top-[65px] sm:h-screen w-full  bg-[black]">
          <img
            src={Prod}
            alt=""
            className=" h-full opacity-70 w-full  object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Our Products
            </h2>
          </div>
        </div>
      </div>

      <div className="products-main my-[4em] overflow-hidden">
        <div className="bg-[#5DDDFF] pt-8">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <div className="product-flex px-[2em] sm:px-[4em] flex flex-col sm:flex-row justify-between items-start gap-[3em] w-full  mx-auto">
              <Scroll
                animationDirection={"left"}
                possibleClass="product-text w-full sm:w-[45%]"
              >
                <img
                  className="h-[237px] w-full object-cover"
                  src={Asset}
                  alt=""
                />
                <div className="flex items-center">
                  <h3 className="mr-4 text-[24px] text-[#001D45]">
                    Asset Backed Note
                  </h3>
                  <img src={Target} alt="" className="w-[24px] h-[24px]" />
                </div>
                <p className="text-[14px] text-justify">
                  This debt note is extended for the purpose of providing
                  support in the expansion of business operations, through the
                  acquisition of assets. Structuring of this credit, is Link
                  function of the cashflow of the assets, and associated risk
                  and is available to businesses seeking to finance cash flow
                  generating or receivable backed asset, at low covenants.
                </p>
                <Link
                  to="/scm/asset-backed-note"
                  className="read-more text-[white] text-[16px] leading-[32px] border-b-[1px] border-b-solid border-b-transparent transition-all duration-400 ease-in-out hover:border-b-[1px] hover:border-b-solid hover:border-b-[white] "
                >
                  View requirements
                </Link>
              </Scroll>

              <Scroll
                animationDirection={"right"}
                possibleClass="product-text w-full sm:w-[45%]"
              >
                <img
                  src={Ecosystem}
                  className="h-[237px] w-full object-cover"
                  alt=""
                />
                <div className="flex items-center">
                  <h3 className="mr-4 text-[24px] text-[#001D45]">
                    Eco- System Financing Note
                  </h3>
                  <img src={Target} alt="" className="w-[24px] h-[24px]" />
                </div>

                <p className="text-[14px] text-justify">
                  Eco-System financing leverages on relationship to unlock value
                  for businesses in the value chain or ecosystem. This financing
                  arrangement integrates financial services into a non-financial
                  value chain, thereby unlocking long term opportunities in
                  businesses.
                </p>
                <Link
                  to="/scm/eco-system-financing-note"
                  className="read-more text-[white] text-[16px] leading-[32px] border-b-[1px] border-b-solid border-b-transparent transition-all duration-400 ease-in-out hover:border-b-[1px] hover:border-b-solid hover:border-b-[white] "
                >
                  View requirements
                </Link>
              </Scroll>
            </div>
          </div>
        </div>
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
          <div className="product-flex px-[2em] sm:px-[4em] flex flex-col sm:flex-row justify-between items-start gap-[3em] w-full mt-[5em] mx-auto">
            <Scroll
              animationDirection={"left"}
              possibleClass="product-text w-full sm:w-[45%]"
            >
              <img
                src={Working}
                className="h-[237px] w-full object-cover"
                alt=""
              />
              <div className="flex items-center">
                <h3 className="mr-4 text-[24px] text-[#001D45]">
                  Working Capital Financing(WCF) Note
                </h3>
                <img src={Target} alt="" className="w-[24px] h-[24px]" />
              </div>

              <p className="text-[14px] text-justify">
                Our WCF offers business owners a breather in financing, the
                variable part of working capital to cover only liquidity for
                production expansion and execution for targeted customers. This
                is directly linked with revenue and approved invoices from
                qualified customers and can be financed either with senior or
                mezzanine debt. WCF can be structured as a line of credit and
                secured by the cash flow of the business at the top line, with
                repayment recognized as a first line charge item, on the cash
                flow waterfall
              </p>
              <Link
                to="/scm/working-capital-financing"
                className="read-more text-[#5DDDFF] text-[16px] leading-[32px] border-b-[1px] border-b-solid border-b-transparent transition-all duration-400 ease-in-out hover:border-b-[1px] hover:border-b-solid hover:border-b-[#5DDDFF] "
              >
                View requirements
              </Link>
            </Scroll>
            <Scroll
              animationDirection={"right"}
              possibleClass="product-text w-full sm:w-[45%]"
            >
              <img
                src={Inventory}
                className="h-[237px] w-full object-cover"
                alt=""
              />
              <div className="flex items-center">
                <h3 className="mr-4 text-[24px] text-[#001D45]">
                  Inventory Financing Note
                </h3>
                <img src={Target} alt="" className="w-[24px] h-[24px]" />
              </div>

              <p className="text-[14px] text-justify">
                This debt note provides liquidity against inventory of a
                business, particularly in the area of financing the purchase of
                raw materials required for production of inventory. It is
                available to businesses that need to hold stock for established
                customers. It also can be structured for the inventory to be
                owned by the investor. The business will be a direct off take of
                inventory produced on a take-or-pay arrangement. Warehousing
                arrangement is required to ensure proper management of
                collateral.
              </p>
              <Link
                to="/scm/inventory-financing-note"
                className="read-more text-[#5DDDFF] text-[16px] leading-[32px] border-b-[1px] border-b-solid border-b-transparent transition-all duration-400 ease-in-out hover:border-b-[1px] hover:border-b-solid hover:border-b-[#5DDDFF] "
              >
                View requirements
              </Link>
            </Scroll>
          </div>
        </div>
        <div className="bg-[#5DDDFF] pt-8">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <div className="product-flex px-[2em] sm:px-[4em] flex flex-col sm:flex-row justify-between items-start gap-[3em] w-full mt-[5em] mx-auto">
              <Scroll
                animationDirection={"left"}
                possibleClass="product-text w-full sm:w-[45%]"
              >
                <img
                  src={Receive}
                  className="h-[237px] w-full object-cover"
                  alt=""
                />
                <div className="flex items-center">
                  <h3 className="mr-4 text-[24px] text-[#001D45]">
                    Receivable Line of Credit Note
                  </h3>
                  <img src={Target} alt="" className="w-[24px] h-[24px]" />
                </div>

                <p className="text-[14px] text-justify">
                  A Receivable Line of Credit is available to businesses with
                  stable contractual production off take that can be scaled. The
                  facility is backed by cash flow from pledged invoices. Its
                  interest is paid according to the amount of money borrowed.
                </p>
                <Link
                  to="/scm/receivable-line-of-credit"
                  className="read-more text-[white] text-[16px] leading-[32px] border-b-[1px] border-b-solid border-b-transparent transition-all duration-400 ease-in-out hover:border-b-[1px] hover:border-b-solid hover:border-b-[white] "
                >
                  View requirements
                </Link>
              </Scroll>

              <Scroll
                animationDirection={"right"}
                possibleClass="product-text w-full sm:w-[45%]"
              >
                <img src={Invoice} alt="" className="h-[237px] w-full" />
                <div className="flex items-center">
                  <h3 className="mr-4 text-[24px] text-[#001D45]">
                    Invoice Discounting Note
                  </h3>
                  <img
                    src={Target}
                    alt=""
                    className="w-[24px] h-[24px] object-cover"
                  />
                </div>

                <p className="text-[14px] text-justify">
                  Invoice discounting allows a business to leverage on the value
                  of its account receivables (invoice) to access financing for
                  the business. The invoice will be pledged as the collateral.
                  The amount issued on this facility is less than the total
                  amount of outstanding receivables.
                </p>
                <Link
                  to="/scm/invoice-discounting-note"
                  className="read-more text-[white] text-[16px] leading-[32px] border-b-[1px] border-b-solid border-b-transparent transition-all duration-400 ease-in-out hover:border-b-[1px] hover:border-b-solid hover:border-b-[white] "
                >
                  View requirements
                </Link>
              </Scroll>
            </div>
          </div>
        </div>
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
          <div className="product-flex px-[2em] sm:px-[4em] flex flex-col sm:flex-row justify-between items-start gap-[3em] w-full mt-[5em] mx-auto">
            <Scroll
              animationDirection={"left"}
              possibleClass="product-text w-full sm:w-[45%]"
            >
              <img src={VC} className="h-[237px] w-full object-cover" alt="" />
              <div className="flex items-center">
                <h3 className="mr-4 text-[24px] text-[#001D45]">
                  Venture Capital Financing
                </h3>
                <img src={Target} alt="" className="w-[24px] h-[24px]" />
              </div>

              <p className="text-[14px] text-justify">
                Our Venture Capital Financing provides business growth capital
                to early and later stage startups. It is intended to propel a
                product’s success or growth in the marketplace. This however
                does not always involve the disbursement of credit facility; it
                can be provided in form of technical or managerial expertise.
              </p>
              <Link
                to="/scm/venture-capital-financing"
                className="read-more text-[#5DDDFF] text-[16px] leading-[32px] border-b-[1px] border-b-solid border-b-transparent transition-all duration-400 ease-in-out hover:border-b-[1px] hover:border-b-solid hover:border-b-[#5DDDFF] "
              >
                View requirements
              </Link>
            </Scroll>

            <Scroll
              animationDirection={"right"}
              possibleClass="product-text w-full sm:w-[45%]"
            >
              <img
                src={Export}
                className="h-[237px] w-full object-cover"
                alt=""
              />
              <div className="flex items-center">
                <h3 className="mr-4 text-[24px] text-[#001D45]">
                  Export and Trade Financing Note
                </h3>
                <img src={Target} alt="" className="w-[24px] h-[24px]" />
              </div>

              <p className="text-[14px] text-justify">
                This debt note facilitates trade and commerce, making it
                possible and easier for importers/exporters to transact through
                trade. Trade finance supports companies to trade either
                domestically or internationally. This can be structured, to the
                individual transactions under the following, invoice factoring,
                account receivable factoring, consignment purchases.
              </p>
              <Link
                to="/scm/export-and-trade-financing"
                className="read-more text-[#5DDDFF] text-[16px] leading-[32px] border-b-[1px] border-b-solid border-b-transparent transition-all duration-400 ease-in-out hover:border-b-[1px] hover:border-b-solid hover:border-b-[#5DDDFF] "
              >
                View requirements
              </Link>
            </Scroll>
          </div>
        </div>
        <div className="bg-[#5DDDFF] pt-8">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <div className="product-flex px-[2em] sm:px-[4em] flex flex-col sm:flex-row justify-between items-start gap-[3em] w-full mt-[5em] mx-auto">
              <Scroll
                animationDirection={"left"}
                possibleClass="product-text w-full sm:w-[45%]"
              >
                <img
                  src={LBO}
                  className="h-[237px] w-full object-cover"
                  alt=""
                />
                <div className="flex items-center">
                  <h3 className="mr-4 text-[24px] text-[#001D45]">
                    Leverage Buy-Out Note
                  </h3>
                  <img src={Target} alt="" className="w-[24px] h-[24px]" />
                </div>

                <p className="text-[14px] text-justify">
                  The LBO note supports businesses in the acquisition of a
                  profitable target company with growth opportunities. It can be
                  financed by using debt instrument. The asset of the company
                  being acquired, and the acquiring company is used as a
                  collateral for facility. Often asset backed, LBO is available
                  to businessmen who desire to acquire another company as a
                  means of diversifying their portfolio.
                </p>
                <Link
                  to="/scm/leverage-buy-out"
                  className="read-more text-[white] text-[16px] leading-[32px] border-b-[1px] border-b-solid border-b-transparent transition-all duration-400 ease-in-out hover:border-b-[1px] hover:border-b-solid hover:border-b-[white] "
                >
                  View requirements
                </Link>
              </Scroll>

              <Scroll
                animationDirection={"right"}
                possibleClass="product-text w-full sm:w-[45%]"
              >
                <img
                  src={Project}
                  className="h-[237px] w-full object-cover"
                  alt=""
                />
                <div className="flex items-center">
                  <h3 className="mr-4 text-[24px] text-[#001D45]">
                    Project Financing Note
                  </h3>
                  <img src={Target} alt="" className="w-[24px] h-[24px]" />
                </div>

                <p className="text-[14px] text-justify">
                  Project finance is available to fund major project. The
                  finance is structured around the project backed by an off take
                  or purchase agreement as the primary source of repayment. The
                  facility structure relies primarily on the project’s cash flow
                  for repayment, with the project assets, rights and interest
                  held as secondary collateral.
                </p>
                <Link
                  to="/scm/project-financing-note"
                  className="read-more text-[white] text-[16px] leading-[32px] border-b-[1px] border-b-solid border-b-transparent transition-all duration-400 ease-in-out hover:border-b-[1px] hover:border-b-solid hover:border-b-[white] "
                >
                  View requirements
                </Link>
              </Scroll>
            </div>
          </div>
        </div>
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
          <div className="product-flex px-[2em] sm:px-[4em] flex flex-col sm:flex-row justify-between items-start gap-[3em] w-full mt-[5em] mx-auto">
            <Scroll
              animationDirection={"left"}
              possibleClass="product-text w-full sm:w-[45%]"
            >
              <img
                src={Lend}
                className="h-[237px] w-full object-cover"
                alt=""
              />
              <div className="flex items-center">
                <h3 className="mr-4 text-[24px] text-[#001D45]">
                  On-Lending Financing Note
                </h3>
                <img src={Target} alt="" className="w-[24px] h-[24px]" />
              </div>

              <p className="text-[14px] text-justify">
                This facility is available to intermediaries who offer financing
                to its customers or projects in order to create value. This note
                could be structured as direct investment to the financial
                institution of their customer and is backed by the cash flow of
                the financial institution.
              </p>
              <Link
                to="/scm/on-lending-financing"
                className="read-more text-[#5DDDFF] text-[16px] leading-[32px] border-b-[1px] border-b-solid border-b-transparent transition-all duration-400 ease-in-out hover:border-b-[1px] hover:border-b-solid hover:border-b-[#5DDDFF] "
              >
                View requirements
              </Link>
            </Scroll>
            <Scroll
              animationDirection={"right"}
              possibleClass="product-text w-full sm:w-[45%]"
            >
              <img
                src={Purchase}
                className="h-[237px] w-full object-cover"
                alt=""
              />
              <div className="flex items-center">
                <h3 className="mr-4 text-[24px] text-[#001D45]">
                  Purchase Order Financing Note
                </h3>
                <img src={Target} alt="" className="w-[24px] h-[24px]" />
              </div>

              <p className="text-[14px] text-justify">
                This financing option can be structured as a line of credit
                secured by the company’s active Purchase Orders (PO). It is
                available as fallback to businesses with low cash flow reserve,
                due to long receivable days, for the execution of the PO.
              </p>
              <Link
                to="/scm/purchase-order-financing"
                className="read-more text-[#5DDDFF] text-[16px] lea\ding-[32px] border-b-[1px] border-b-solid border-b-transparent transition-all duration-400 ease-in-out hover:border-b-[1px] hover:border-b-solid hover:border-b-[#5DDDFF] "
              >
                View requirements
              </Link>
            </Scroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
