import React from "react";
import Adviser from "../../assets/advisers.jpeg";
import Investor from "../../assets/investor.jpeg";
import { Link } from "react-router-dom";
import Scroll from "../../Reusables/Animation/Scroll";
import Dea from "../../assets/img/dealbook.jpeg";
const Deal = () => {
  // const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className="h-[60vh] relative top-[65px] sm:h-screen w-full  bg-[black]">
          <img
            src={Dea}
            alt=""
            className=" h-full opacity-70 w-full object-cover object-center"
          />
        </div>

        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Deal Book
            </h2>
          </div>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] py-[2em] sm:px-[4em] py-[6em] overflow-hidden">
        <div className=" flex flex-col sm:flex-row justify-between gap-[3em]">
          <Scroll
            animationDirection={"left"}
            possibleClass="deal-investor w-full sm:w-[50%]"
          >
            <Link
              to="../sfa/investor"
              className="text-[#001D45] text-[24px] leading-[32px] hover:underline"
            >
              <p>Investors</p>
              <p>(Looking for deals to invest in)</p>
            </Link>
            <div className=" w-full  h-[80%]">
              <img src={Investor} className="w-full h-full mt-[1em] " alt="" />
            </div>
          </Scroll>

          <Scroll
            animationDirection={"right"}
            possibleClass="deal-potential w-full sm:w-[50%]"
          >
            <Link
              to="../sfa/potential"
              className="text-[#001D45] text-[24px] leading-[32px] hover:underline"
            >
              <p>Potential Clients</p>
              <p>(Looking for Advisers)</p>
            </Link>
            <div className="w-full h-[80%]">
              <img src={Adviser} className="w-full h-full mt-[1em] " alt="" />
            </div>
          </Scroll>
        </div>
      </div>
    </div>
  );
};

export default Deal;
