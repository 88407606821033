import React, { useEffect } from "react";
import SCMHeader from "../Reusables/Header/SCMHeader";
import Products from "./components/Products";
import Footer from "../Reusables/Footer/Footer";
const OurProducts = () => {
  useEffect(() => {
    document.title = "Our Products";
  }, []);
  return (
    <div>
      <SCMHeader />
      <Products />
      <Footer />
    </div>
  );
};

export default OurProducts;
