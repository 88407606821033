import React from "react";
import Scroll from "../../Reusables/Animation/Scroll";
const Project = () => {
  const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className="h-[60vh] relative top-[65px] sm:h-screen w-full  bg-[black]">
          <img
            src={test.Img144}
            alt=""
            className=" h-full opacity-70 w-full  object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Project Financing Note
            </h2>
          </div>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] career-container py-[2em] px-[2em] sm:px-[4em] overflow-hidden">
        <Scroll
          animationDirection={"left"}
          possibleClass="career-header text-[#001D45] mt-[2em] pb-[30px]"
        >
          <h2 className="text-[40px] leading-[48px] ">
            Project Financing Note
          </h2>
          <p className="text-[16px] leading-[24px] pb-[30px] text-justify">
            Project finance is available to fund major project and structuring
            the finance around the project backed by an off take or purchase
            agreement as the primary source of repayment.
          </p>
          <p>
            The facility structure relies primarily on the project’s cash flow
            for repayment, with the project assets, rights and interest held as
            secondary collateral.
          </p>
        </Scroll>

        <Scroll
          animationDirection={"right"}
          possibleClass="product-main relative w-full h-full"
        >
          <table className="w-full border-collapse">
            <tr>
              <th>SECTOR</th>
              <th>Agnostic, B2B</th>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                CLUSTER OF INTEREST
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                Manufacturing, Logistics, AGRO Services, Telecoms, Construction,
                Service Companies, Food Beverage production, Pharmaceuticals,
                Automobile
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                TRANSACTION SIZE
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                20-100 million Naira
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                PRICING
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                6% IRR
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                TENOR
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                180days rolling cycle
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                PROCESSING TIME
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                7-14 days
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                REPAYMENT SOURCE
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                Operational Cashflow of the project
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                REQUIREMENTS
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                <li>Equity Contribution up to 20% minimum</li>
                <li>High margin cashflow</li>
                <li>Well established product clientele</li>
                <li>Strategic value add opportunities</li>
                <li>Offtake agreement</li>
                <li>Execution/project cost schedule</li>
                <li>Experience of the Promoter</li>
                <li>Strong management team</li>
              </td>
            </tr>
          </table>
        </Scroll>
      </div>
    </div>
  );
};

export default Project;
