import React from "react";
import Scroll from "../../Reusables/Animation/Scroll";
import Logo from "../../assets/logo.jpeg";
import "./index.scss";
const Philosophy = () => {
  const philosophyData = [
    {
      header: "Proprietary Origination",
      contents: [
        "Depth Of Local Network",
        "Compelling Value",
        "OEMs ecosystem",
        "Better selection",
        "Strong funding",
      ],
    },
    {
      header: "Value Add/Creation",
      contents: [
        "Funding Efficiency",
        "Partnership",
        "OEMs Support",
        "Cost Efficiency",
      ],
    },
    {
      header: "Pure Instrument",
      contents: [
        "Fixed income",
        "Structured to fit",
        "Efficiency coverage structure",
        "Asset collaterised",
      ],
    },
    {
      header: "Active Risk Management",
      contents: [
        "Asset holding to maturity",
        "High frequency touchpoint on asset and performance",
        "Secondary market assurance through OEMs deals",
      ],
    },
  ];
  return (
    <div>
      <div className="bg-[#5DDDFF]">
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] fund-main py-[2em] text-[24px] px-[2em] sm:px-[4em] leading-[32px] text-[black] overflow-hidden">
          <Scroll
            animationDirection={"left"}
            possibleClass="about-the-fund sect"
          >
            <h4 className="text-[24px] leading-[32px] text-[black] mb-[10px] font-[600]">
              The Fund Philosophy
            </h4>
            <p className="text-[16px] leading-[24px] text-[black]">
              Sthenic Funds focus on funding mainly working capital and other
              short-term finances that represent unique opportunities for
              business. The funds are usually utilised for the growth of the
              company and to facilitate value chain opportunities. It is
              premised on the principle that “working capital improves the cash
              flow of the business”, that funds should be invested at all stages
              of the business cycle from start- up to growth. Our philosophy is
              to work with clients on a long-term basis, both from the points of
              investment and business strategy implementation.
            </p>
          </Scroll>
          <div className="sect">
            <Scroll animationDirection={"right"} possibleClass="mb-[20px]">
              <h2 className="text-[24px] mb-[10px] font-[600]">Philosophy</h2>
              <ul className="text-[16px]">
                <li className="flex items-center my-[5px]">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                  <span className="font-[600]"> Competitive Return: </span>{" "}
                  Deliver competitive spread above market in fixed yield.
                </li>
                <li className="flex items-center my-[5px]">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                  <span className="font-[600]">Migitated Risks:</span> Risks
                  migitated by execution structure.
                </li>
                <li className="flex items-center my-[5px]">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                  <span className="font-[600]">Capital Protection:</span>{" "}
                  Downside protection of invested capital.
                </li>
                <li className="flex items-center my-[5px]">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                  <span className="font-[600]">Impact:</span> Job creation,
                  economic growth.
                </li>
              </ul>
            </Scroll>
            <Scroll animationDirection={"right"} possibleClass="point">
              <h2 className="text-[24px] mb-[10px] font-[600]">
                Sthenic Funds are built on four foundations:
              </h2>
              <ul className="text-[16px]">
                <li className="flex items-center my-[5px]">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                  Effective origination and structuring;
                </li>
                <li className="flex items-center my-[5px]">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                  Strong investment and management capabilities;
                </li>
                <li className="flex items-center my-[5px]">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                  Hands-on, “boots on ground” management of the underlying
                  business/transaction through high frequency touch points; and
                </li>
                <li className="flex items-center my-[5px]">
                  <img src={Logo} alt="" className="w-[25px] h-[25px] mr-2" />
                  Intentionally-created exit ecosystem.
                </li>
              </ul>
            </Scroll>
          </div>
        </div>
      </div>
      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] fund-main py-[2em] text-[24px] px-[2em] sm:px-[4em] leading-[32px] text-[black] overflow-hidden">
        <div className="thesis">
          <h3 className="text-[40px] font-[600]">
            Investment Philosophy/Thesis
          </h3>
          <p className="mb-[30px]">
            The Fund Manager's success rate is increased by its strength in the
            following areas:
          </p>
          <div className="thesis-grid">
            {philosophyData.map((e, i) => (
              <Scroll
                animationDirection={"left"}
                possibleClass="thesis-item w-full relative"
                key={i}
              >
                <h4 className="mb-[1em] md:absolute top-0 left-3 bg-[gray] py-1 rounded-md text-[20px] text-center w-full">
                  {e.header}
                </h4>
                <ul className="text-[16px] rounded-full border p-10 mt-5 min-h-[300px]">
                  {e.contents.map((l, i) => (
                    <li className="flex my-[5px] text-[16px]" key={i}>
                      <img
                        src={Logo}
                        alt=""
                        className="w-[25px] h-[25px] mr-2"
                      />
                      {l}
                    </li>
                  ))}
                </ul>
              </Scroll>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Philosophy;
