import React,{useEffect} from "react";
import SFAHeader from "../Reusables/Header/SFAHeader";
import Footer from "../Reusables/Footer/Footer";
import Career from "./components/Career";
const SFACareer = () => {
  useEffect(() => {
    document.title = "SFA Career";
  }, []);
  return (
    <div>
      <SFAHeader />
      <Career />
      <Footer />
    </div>
  );
};

export default SFACareer;
