import React from "react";

import Contac from "../../assets/img/contact.jpeg";
import ContactForm from "../../Reusables/contact_form";
const Contact1 = () => {
  // const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className=" w-full h-[70vh] sm:h-screen  bg-[black]">
          <img
            src={Contac}
            alt=""
            className=" h-full opacity-70 w-full  object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Contact Us
            </h2>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default Contact1;
