import React from "react";
import Africa from "../../assets/image 1.png";
import Local from "../../assets/Rectangle 61.png";
import Scroll from "../../Reusables/Animation/Scroll";
import "./index.scss";
const Opportunity = () => {
  return (
    <div>
      <div className="about-us-title sect text-center">
        <h2 className="text-[25px] md:text-[35px] lg:text-[60px] text-[#5DDDFF]">
          African Opportunities
        </h2>
      </div>
      <div className="bg-[#5DDDFF]">
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] products-main relative w-full h-full py-[30px] overflow-hidden">
          <div className="product-flex flex-col-reverse flex md:flex-row justify-between items-start gap-[3em] w-[90%] mx-[auto] mt-[5em] overflow-hiden">
            <Scroll
              animationDirection={"left"}
              possibleClass="product-text w-full md:w-[45%]"
            >
              <h4 className="text-[28px] text-[#001D45] font-[600]">
                Unleashing the Potential of a Vibrant Market
              </h4>
              <p className="my-[10px]">
                Africa is the second largest and second most populous continent
                in the world, with an estimated population of over 1.3 billion
                people. The continent has a landmass of 30.2 million square
                kilometres, surpassing those of the United States, China, India
                and Europe.
              </p>
              <p className="my-[10px]">
                Africa represents a land of opportunity, with a growing middle
                class, abundant natural resources and a youth population that is
                increasingly and highly tech-savvy and entrepreneurial. By 2030,
                it is expected that young Africans would constitute 42% of the
                global youth population – World Economic Forum. This translates
                to changes in investment and unique innovations.
              </p>
              <p className="my-[10px]">
                The continent has huge economic potentials, with several sectors
                currently experiencing significant growth. With expansions
                across technology, agriculture, e-commerce and renewable energy,
                these industries are driven by factors such as; increased mobile
                and internet penetration rates, growing youth population and a
                groundswell of technological advancement. In 2022, mobile phone
                subscribers stood at 46% in Sub-Saharan Africa, while Smartphone
                adoption was put at 64% and expected to expand by 50% and 75% by
                2025, according to GSMA report.
              </p>
              <p className="my-[10px]">
                Overall, the growth of these sectors represents a significant
                opportunity for businesses and investors looking to tap into the
                African market. At Sthenic, we are strategically positioning
                ourselves to explore these opportunities and unlock growth.
              </p>
            </Scroll>

            <Scroll
              animationDirection={"right"}
              possibleClass=" w-full md:w-[45%]"
            >
              <img src={Africa} alt="" className="w-full" />
            </Scroll>
          </div>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] products-main relative w-full h-full py-[30px] overflow-hidden">
        <div className="product-flex flex flex-col md:flex-row justify-between items-start gap-[3em] w-[90%] mx-[auto] mt-[5em]">
          <Scroll
            animationDirection={"left"}
            possibleClass="product-img w-full md:w-[45%]"
          >
            <img src={Local} alt="" className="w-full" />
          </Scroll>

          <Scroll
            animationDirection={"right"}
            possibleClass="product-text w-full md:w-[45%]"
          >
            <h4 className="text-[28px] text-[#001D45] font-[600]">
              Tapping into a Resilient Private Debt Market
            </h4>

            <p className="my-[10px]">
              Africa's private debt market is rapidly growing,, with Assets
              Under Management (AUM) of over $15 billion, according to the
              African Private Equity and Venture Capital Association. Despite
              this growth, the private debt market in Africa remains
              considerably untapped, offering significant opportunities for
              investors.
            </p>
            <p className="my-[10px]">
              Fundraising for private debt funds in Africa increased
              significantly, with $2.3 billion raised in 2021, up from $1.3
              billion in 2020. This suggests that investors are increasingly
              interested in the African private debt market. Although the total
              value of these deals also decreased from $1.4 billion in 2020 to
              $1.1 billion in 2021, we are still optimistic about this market.
            </p>
            <p className="my-[10px]">
              Despite some inherent risks in the African private debt market, it
              contains a wealth of opportunities. With a growing middle class,
              an expanding consumer base, and a range of untapped industries,
              the growth potential is significant. We also expect the long-term
              growth prospects for the continent to remain strong, backed by the
              African Continental Free Trade Area (AfCFTA) agreement expected to
              boost cross-border trade and investment.
            </p>
            <p className="my-[10px]">
              We are walking this road with investors and high margin firms to
              drive positive change in Africa, promote value investing, support
              sustainable businesses, and create job opportunities for local
              communities.
            </p>
          </Scroll>
        </div>
      </div>
    </div>
  );
};

export default Opportunity;
