import React,{useEffect} from "react";
import Contact from "./components/Contact";
import SFAHeader from "../Reusables/Header/SFAHeader";
import Footer from "../Reusables/Footer/Footer";
const SFAContact = () => {
  useEffect(() => {
    document.title = "SFA Contact";
  }, []);
  return (
    <div>
      <SFAHeader />
      <Contact />
      <Footer />
    </div>
  );
};

export default SFAContact;
