import React,{useEffect} from 'react'
import Early from './components/Early'
import MainHeader from '../Reusables/Header/MainHeader'
import Footer from '../Reusables/Footer/Footer'
const EarlyCareer = () => {
  useEffect(() => {
    document.title = "Career";
  }, []);
  return (
    <div>
        <MainHeader/>
        <Early/>
        <Footer/>
    </div>
  )
}

export default EarlyCareer