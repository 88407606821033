import React, {useEffect} from 'react'
import MainHeader from '../Reusables/Header/MainHeader'
import Footer from '../Reusables/Footer/Footer'
import Enquiry from '../Reusables/Enquiry/Enquiry'
import Histor from './components/Histor'
const History = () => {
  useEffect(() => {
    document.title = "History";
  }, []);
  return (
    <div>
        <MainHeader/>
        <Histor/>
        <Enquiry/>
        <Footer/>
    </div>
  )
}

export default History