import React,{useEffect} from 'react'
import SCMEarly from './components/SCMEarly'
import SCMHeader from '../Reusables/Header/SCMHeader'
import Footer from '../Reusables/Footer/Footer'
const SCMEarlyCareer = () => {
  useEffect(() => {
    document.title = "SCM Career";
  }, []);
  return (
    <div>
        <SCMHeader/>
        <SCMEarly/>
        <Footer/>
        </div>
  )
}

export default SCMEarlyCareer