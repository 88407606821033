import React,{useEffect} from 'react'
import SFAHeader from '../Reusables/Header/SFAHeader'
import Service from './components/Service'
import Footer from '../Reusables/Footer/Footer'
const Services = () => {
  useEffect(() => {
    document.title = "SFA Services";
  }, []);
  return (
    <div>
        <SFAHeader/>
        <Service/>
        <Footer/>
        </div>
  )
}

export default Services