import React from "react";
import Scroll from "../../Reusables/Animation/Scroll";
import "./index.scss";
const Stor = () => {
  return (
    <div>
      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] py-[2em] sm:px-[4em] overflow-hidden bg-[#001D45] text-white">
        <Scroll animationDirection={"left"} possibleClass="py-10 sect">
          <h2 className="text-[25px] md:text-[35px] lg:text-[60px] text-center -mt-10">
            Our Story
          </h2>
          <div>
            <p className="text-[16px] mb-[10px] text-justify">
              At Sthenic Capital Management, we are aware that the pathway to
              greatness often starts with humble beginnings. This is why we are
              proud to say that our firm was incorporated in 2020 – the Covid
              year. Despite the challenges that followed the outbreak of the
              pandemic, we showed our belief in resilience and adaptability as
              qualities any successful investor should possess. We have
              continued to exhibit the qualities in managing capital since our
              company was set up.
            </p>
            <p className="text-[16px] mb-[10px] text-justify">
              Our firm was established on an existing and robust advisory
              ecosystem, with clear mandates to grow investment into profitable
              ventures for our investors and provide financing to small and
              medium-scale businesses. We have continued to carry out these
              mandates across different firms and industries in our portfolio.
            </p>
            <p className="text-[16px] mb-[10px] text-justify">
              In the past years of operation, our Sthenic Special Situation Debt
              Fund (SSDF)- our flagship private debt fund, has financed several
              businesses in multiple investments and counting. We have since
              created other to serve specific niches in the market place and in
              compliance with regulatory requirements. We have built the
              capacity to channel wealth seeking competitive returns to markets
              where it is in short supply and have created significant values in
              the process.
            </p>
            <p>
              At Sthenic Capital Management, we are here to leverage on the
              opportunities provided by the markets. It is our desire to push
              for a vital shift of the small and mid-sized markets as the engine
              of growth and large enterprises in Africa.
            </p>
          </div>
        </Scroll>
      </div>
    </div>
  );
};

export default Stor;
