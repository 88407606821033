import React, { useState } from "react";
import Vector from "../../assets/Vector.svg";
import Vision from "../../assets/vision.png";
import Target from "../../assets/target.png";
import We from "../../assets/we.png";
import Bg2 from "../../assets/bg-2.jpeg";
import Bg3 from "../../assets/bg-3.jpeg";
import Logo from "../../assets/logo.jpeg";
import { Link } from "react-router-dom";
import "./index.scss";
import useWindowSize from "../../hooks/useWindowSize";
import Scroll from "../../Reusables/Animation/Scroll";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const Welcome = () => {
  const [view, setView] = useState(0);
  const { width } = useWindowSize();
  const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div className=" cover-container  ">
      <div className="h-full relative ">
        <div className=" w-full h-[70vh] relative top-[65px] sm:h-screen bg-[black] overflow-hidden">
          <Carousel
            autoPlay={true}
            interval={4000}
            infiniteLoop={true}
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
          >
            <img
              src={test.City}
              alt={"ima"}
              className="  opacity-50 w-full h-full"
            />
            <img
              src={Bg2}
              alt={"ima"}
              className="  opacity-50 w-full h-full  object-cover object-center"
            />
            <img
              src={Bg3}
              alt={"ima"}
              className="  opacity-50 w-full h-full  object-cover object-center"
            />
          </Carousel>
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <h2 className="text-[25px] md:text-[35px] lg:text-[60px]  mr-0 ml-0 2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px]">
            Sthenic Group
          </h2>
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h3 className=" text-[20px] lg:text-[24px] font-[800] mb-1">
              Welcome to the solution powerhouse!
            </h3>
            <p
              className={`text-[16px] mr-0 ml-0 w-[90%] md:w-[60%] lg:w-[50%] xl:w-[40%]   ${
                width < 340 ? "hidden" : "block"
              }`}
            >
              We are an African-focused finance and investment management firm
              that prioritizes financial sustainability of our clients with
              simplified approach to corporate financing, investment and wealth
              management. Our approach caters to your financial needs and offers
              cutting edge financial advisory services.
            </p>
          </div>
        </div>
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px]">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] flex flex-col lg:flex-row justify-between absolute w-full bottom-[-3em]  px-[2em] sm:px-[4em] text-white items-end">
            <div
              className={`text-[20px] font-600 ${
                view === 1 ? "bg-[#001D45] " : "bg-transparent"
              }  border-[1px] transition-all duration-700 ease-in-out border-solid border-[white] lg:border-none  p-2 sm:p-4 rounded-[7px] w-full mb-[10px]`}
            >
              <Link
                to="/scm/about-us"
                className="flex justify-between  md:justify-start md:gap-x-4  items-center mb-[10px]"
              >
                <h3 className="cat-link text-[16px] lg:text-[20px] font-[600] hover:text-[#5DDDFF]">
                  Sthenic Capital Management Limited
                </h3>
                <div className="cat-icon hidden lg:block">
                  <img src={Vector} alt="" />
                </div>
              </Link>
            </div>
            <div
              className={`text-[20px] font-600 ${
                view === 1 ? "bg-[#001D45] " : "bg-transparent"
              }  border-[1px] transition-all duration-700 ease-in-out border-solid border-[white] lg:border-none  p-2 sm:p-4 rounded-[7px] w-full mb-[10px]`}
            >
              <Link
                to="#"
                className="flex justify-between  md:justify-center md:gap-x-4  items-center mb-[10px]"
              >
                <h3 className="cat-link text-[16px] lg:text-[20px] font-[600] hover:text-[#5DDDFF]">
                  SFBG
                </h3>
                <div className="cat-icon hidden lg:block">
                  <img src={Vector} alt="" />
                </div>
              </Link>
            </div>

            <div
              className={`text-[20px] font-600 ${
                view === 2 ? "bg-[#001D45]" : "bg-transparent"
              }  border-[1px] transition-all duration-700 ease-in-out border-solid border-[white] lg:border-none  p-2 sm:p-4 rounded-[7px] w-full mb-[10px]`}
            >
              <Link
                to="/sfa/about-us"
                className="flex justify-start  lg:justify-end md:gap-x-4 items-center mb-[10px] cursor-pointer"
              >
                <h3 className="cat-link text-[16px] lg:text-[20px] font-[600] hover:text-[#5DDDFF]">
                  Sthenic Finance and Advisory Limited
                </h3>
                <div className="cat-icon hidden lg:block">
                  <img src={Vector} alt="" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center vision py-16">
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] vision flex flex-col sm:flex-row justify-between items-start py-[3em] px-[2em] sm:px-[4em] relative overflow-hidden">
          <Scroll
            animationDirection={"left"}
            possibleClass={`p-[1em] w-full sm:w-[45%] `}
          >
            <div
              className="  flex justify-between items-center mb-[2em] cursor-pointer"
              onMouseEnter={() => setView(3)}
              onMouseLeave={() => setView(0)}
            >
              <h3
                className={` transition-all duration-700 ease-in-out ${
                  view === 3 ? "text-[40px]" : "text-[28px]"
                }`}
              >
                Our Vision
              </h3>
              <img
                src={Vision}
                alt=""
                className={`${
                  view === 3 ? "w-[60px] h-[60px]" : "w-[40px] h-[40px]"
                }`}
              />
            </div>
            <p className="text-[18px] text-justify">
              To be the innovative investment platform that catalyses expansion
              of African middle and large enterprises.
            </p>
          </Scroll>

          <Scroll
            animationDirection={"right"}
            possibleClass="p-[1em] w-[45%] w-full sm:w-[45%]"
          >
            <div
              className="flex justify-between items-center mb-[2em] cursor-pointer"
              onMouseEnter={() => setView(4)}
              onMouseLeave={() => setView(0)}
            >
              <h3
                className={` transition-all duration-700 ease-in-out ${
                  view === 4 ? "text-[40px]" : "text-[28px]"
                }`}
              >
                Our Mission
              </h3>
              <img
                src={Target}
                alt=""
                className={`${
                  view === 4 ? "w-[60px] h-[60px]" : "w-[40px] h-[40px]"
                }`}
              />
            </div>
            <p className="text-[18px] text-justify">
              To be among the leading (top 10 in 10 years) catalyst of financial
              sustainability and growth of African businesses through
              diversified financing and investment solutions that deliver
              competitive values to our stakeholders.
            </p>
          </Scroll>
        </div>
      </div>
      <div className="bg-[#5DDDFF] w-full">
        <div className=" py-[3em] mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px]">
          <h2 className="text-[25px] md:text-[35px] lg:text-[60px] text-center text-[#001D45] ">
            Who We are
          </h2>
          <div className="flex flex-col items-center md:flex-row justify-between px-[2em]  sm:px-[4em] py-[2em] h-full relative overflow-hidden">
            <Scroll
              animationDirection="left"
              possibleClass="w-full slide md:w-[47%] mb-[20px]"
            >
              {/* <div className=""> */}
              <p className="text-[18px] mb-[10px] text-justify">
                Sthenic Finance and Advisory Limited (“Sthenic”) is a structured
                finance and investment management company. We especially focus
                on supporting business growth in order to solve short- and
                medium-term liquidity challenges by providing innovative
                financing products and structures. Our strategy is built on
                depth, agility and speed of execution. Through this, we have
                been able to bring together well established investors that are
                seeking for alpha, and growth companies in need of capital that
                unlocks high returns and increases value for all stakeholders.
              </p>
              <p className="text-[18px] mb-[10px] text-justify">
                Founded in 2016 and headquartered in Lagos, Nigeria, Sthenic
                Group has over the years successfully evolved into a finance,
                business advisory, and investment management company. The
                Securities and Exchange Commission (SEC) has given us license to
                operate as a Fund Manager. As a result, we have been authorised
                to operate specialised funds, simplify finances, add value and
                manage risks for our clients. Our company also focuses on
                providing customised solutions that help clients to build a
                solid foundation for the present and a visible pathway for the
                future, making us one of leading players in the finance and
                investment management industry.
              </p>
              <p className="text-[18px] mb-[10px] text-justify">
                We equally guide businesses in Africa to achieve financial
                sustainability and growth. Our company does this through smart
                capital. We are therefore fully dedicated to filling the missing
                middle conundrum in the continent.
              </p>
              {/* </div> */}
            </Scroll>
            <Scroll
              animationDirection="right"
              possibleClass="relative w-full md:w-[50%] flex justify-center sm:block"
            >
              <img src={We} alt="" className="w-full h-full" />
            </Scroll>
          </div>
        </div>
      </div>
      <div className="py-10 px-[2em] sm:px-[4em] text-[18px] text-[#0177B2] mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px]">
        <h2 className="text-[25px] md:text-[35px] lg:text-[60px] text-center text-[#001D45] mb-[30px]">
          CORE PILLARS
        </h2>
        <div className="card-wrapper overflow-hidden">
          <Scroll
            animationDirection="left"
            possibleClass="border-solid border-[1px] border-[#0177B2] p-[1em] rounded-[10px] transition-all duration-400 ease-in-out  cursor-pointer"
          >
            <div className="flex ">
              <img src={Logo} alt="" className="mr-[10px] h-[30px] w-[30px]" />
              <h4 className="text-[18px]">Competent and Committed People</h4>
            </div>
          </Scroll>
          <Scroll
            animationDirection={"right"}
            possibleClass="border-solid border-[1px] border-[#0177B2] p-[1em] rounded-[10px] transition-all duration-400 ease-in-out  cursor-pointer"
          >
            <div className="flex">
              <img src={Logo} alt="" className="mr-[10px] h-[30px] w-[30px]" />
              <h4 className="text-[18px]">Services and Products Innovation</h4>
            </div>
          </Scroll>
          <Scroll
            animationDirection="left"
            possibleClass="border-solid border-[1px] border-[#0177B2] p-[1em] rounded-[10px] transition-all duration-400 ease-in-out  cursor-pointer"
          >
            <div className="flex">
              <img src={Logo} alt="" className="mr-[10px] h-[30px] w-[30px]" />
              <h4 className="text-[18px]">
                Efficient Execution System/ infrastructure
              </h4>
            </div>
          </Scroll>
          <Scroll
            animationDirection={"right"}
            possibleClass="border-solid border-[1px] border-[#0177B2] p-[1em] rounded-[10px] transition-all duration-400 ease-in-out  cursor-pointer"
          >
            <div className="flex">
              <img src={Logo} alt="" className="mr-[10px] h-[30px] w-[30px]" />
              <h4 className="text-[18px]">
                Established Governance & Risk Management
              </h4>
            </div>
          </Scroll>
        </div>
      </div>
      <div className="px-[2em] sm:px-[4em] py-[2em] mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px]">
        <h2 className="text-[25px] md:text-[35px] lg:text-[60px] text-center text-[#001D45] mb-[30px]">
          CORE VALUES
        </h2>
        <div className=" card-wrapper overflow-hidden">
          <Scroll
            animationDirection={"left"}
            possibleClass="border-solid border-[1px] border-[#0177B2] p-[1em] rounded-[10px] transition-all duration-400 ease-in-out  cursor-pointer"
          >
            <div className="flex items-center mb-[10px]">
              <img src={Logo} alt="" className="mr-[10px] h-[30px] w-[30px]" />
              <h4 className="text-[22px]">Vision</h4>
            </div>
            <div className="card-details">
              <p className="text-[18px]">
                We believe the first order principle is clarity of thought and
                understanding of the problem. We approach every need with clear
                vision. As a result, we are well poised to deliver innovative
                solutions that make a huge difference in our world.
              </p>
            </div>
          </Scroll>
          <Scroll
            animationDirection={"left"}
            possibleClass="border-solid border-[1px] border-[#0177B2] p-[1em] rounded-[10px] transition-all duration-400 ease-in-out  cursor-pointer"
          >
            <div className="flex items-center mb-[10px]">
              <img src={Logo} alt="" className="mr-[10px] h-[30px] w-[30px]" />
              <h4 className="text-[22px]">Accessibility</h4>
            </div>
            <div className="card-details">
              <p className="text-[18px]">
                We are available! Booths on ground. And we avail Africans
                especially of our financial products and strategies in order to
                deliver financial sustainability where it matters the most.
              </p>
            </div>
          </Scroll>

          <Scroll
            animationDirection={"right"}
            possibleClass="border-solid border-[1px] border-[#0177B2] p-[1em] rounded-[10px] transition-all duration-400 ease-in-out cursor-pointer "
          >
            <div>
              <div className="flex items-center mb-[10px]">
                <img
                  src={Logo}
                  alt=""
                  className="mr-[10px] h-[30px] w-[30px]"
                />
                <h4 className="text-[22px]">Integrity</h4>
              </div>
              <div className="card-details">
                <p className="text-[18px]">
                  We believe that honesty and transparency are crucial to
                  building enduring relationships with clients and partners.
                  Professionalism is equally our watchword. As a necessity, our
                  company is committed to always working hard and doing what is
                  necessary – even if it is not the easy option.
                </p>
              </div>
            </div>
          </Scroll>

          <Scroll
            animationDirection={"left"}
            possibleClass="border-solid border-[1px] border-[#0177B2] p-[1em] rounded-[10px] transition-all duration-400 ease-in-out cursor-pointer"
          >
            <div>
              <div className="flex items-center mb-[10px]">
                <img
                  src={Logo}
                  alt=""
                  className="mr-[10px] h-[30px] w-[30px]"
                />

                <h4 className="text-[22px]">Leadership</h4>
              </div>
              <div className="card-details">
                <p className="text-[18px]">
                  Leadership is taking responsibility for actions that create
                  desired results. For this reason, we work towards leading by
                  example in all endeavours. We are fully committed to
                  empowering our employees, and helping our clients and partners
                  to attain their full potential.
                </p>
              </div>
            </div>
          </Scroll>

          <Scroll
            animationDirection={"right"}
            possibleClass="border-solid border-[1px] border-[#0177B2] p-[1em] rounded-[10px] transition-all duration-400 ease-in-out  cursor-pointer"
          >
            <div>
              <div className="flex items-center mb-[10px]">
                <img
                  src={Logo}
                  alt=""
                  className="mr-[10px] h-[30px] w-[30px]"
                />
                <h4 className="text-[22px]">Execution</h4>
              </div>
              <div className="card-details">
                <p className="text-[18px]">
                  We believe in getting things done for our clients and partners
                  – and timely too. Ideas do not thrill us, but their execution
                  does. We are committed to acting upon our ideas. Thus, we are
                  convinced that it is by prioritising action that goals can be
                  achieved and real impact can be made in this predictably
                  competitive world.
                </p>
              </div>
            </div>
          </Scroll>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
