import React from "react";
import Broker from "../../assets/broker.jpeg";
import Finance from "../../assets/Project-Finance.jpeg";
import Advisory from "../../assets/advisory.jpeg";
import Equity from "../../assets/equlty.jpeg";
import Merger from "../../assets/merger.jpeg";
import Structure from "../../assets/structure-finance.jpeg";
import Ser from "../../assets/img/our-services.jpeg";

import "./index.scss";
const Service = () => {
  // const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className="h-[60vh] relative top-[65px] sm:h-screen w-full  bg-[black]">
          <img
            src={Ser}
            alt=""
            className=" h-full opacity-70 w-full object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Our Servives
            </h2>
          </div>
        </div>
      </div>

      <div className="bg-[#5DDDFF]">
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] sm:px-[4em] py-[2em] overflow-hidden">
          <div className="services-flex flex-col-reverse sm:flex-row flex justify-between items-center gap-[3em] w-[90%] mt-[5em] mx-auto ">
            <div className="services-text w-full sm:w-[45%] ">
              <h3 className="text-[28px] leading-[36px] mb-[0.5em]">
                Structured Financing
              </h3>
              <p className="text-[14px] leading-[28px] mb-[15px]">
                At Sthenic, we provide comprehensive transaction structuring
                services, from the initial analysis of business objectives to
                offering input into major agreements and clauses. We work
                closely with our clients to ensure that they are fully informed
                and empowered to make strategic decisions every step of the way.
              </p>

              <p className="text-[14px] leading-[28px] mb-[15px]">
                Our team have extensive knowledge and experience in structuring
                and carrying out financing deals across a range of sectors. We
                specialise in creating customised debt, equity, and hybrid
                financing solutions that are tailored to meet the unique needs
                of each client. Our solutions are designed to reduce risk,
                maximise flexibility, and optimise capital structure while
                ensuring that our clients can achieve their financial goals with
                confidence.
              </p>

              <p className="text-[14px] leading-[28px] mb-[15px]">
                We are committed to providing innovative and effective
                structured financing solutions as we offer the experience and
                resources necessary to help you reach the optimal capital
                structure, considering the timeline of your strategic goals. Our
                solutions are designed to provide the flexibility to respond to
                changing business situations or capitalize on growth
                possibilities as they arise.
              </p>
            </div>

            <div className="services-img w-full sm:w-[45%]">
              <img src={Structure} alt="" className="block w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] sm:px-[4em] py-[2em] overflow-hidden">
        <div className="services-flex flex-col sm:flex-row flex justify-between items-center gap-[3em] w-[90%] mt-[5em] mx-auto ">
          <div className="services-img w-full sm:w-[45%]">
            <img src={Merger} alt="" className="block w-full" />
          </div>

          <div className="services-text w-full sm:w-[45%]">
            <h3 className="text-[28px] leading-[36px] mb-[0.5em]">
              Mergers & Acquisitions
            </h3>
            <p className="text-[14px] leading-[28px] mb-[15px]">
              Benefitting from the diverse background of our team, Sthenic
              finance and advisory has experts who have a deep understanding of
              the intricacies involved in structuring, negotiating, and
              executing successful mergers and acquisitions to assist businesses
              looking to expand.
            </p>
            <p className="text-[14px] leading-[28px] mb-[15px]">
              Mergers and acquisitions can be a smart strategy to achieve growth
              goals. At Sthenic, we analyse developing trends across sectors.
              This helps us discover and advise on merger and acquisition
              possibilities that have the potential to yield substantial
              strategic advantages and ultimately maximise rewards to
              transaction parties. We help our customers evaluate strategic
              choices and reduce complexity in transactions such as Leveraged
              Buy-Outs (LBOs), joint ventures, divestitures, and split-offs.
            </p>
            <p className="text-[14px] leading-[28px] mb-[15px]">
              Whether you arelooking to acquire a competitor, merge with a
              complementary business, or sell your company, we’re here to help.
              Our goal is to provide businesses with the information and
              insights they need to compete effectively in a global economy.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-[#5DDDFF]">
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] sm:px-[4em] py-[2em] overflow-hidden">
          <div className="services-flex flex-col-reverse sm:flex-row flex justify-between items-center gap-[3em] w-[90%] mt-[5em] mx-auto ">
            <div className="services-text w-full sm:w-[45%]">
              <h3 className="text-[28px] leading-[36px] mb-[0.5em]">
                Equity Capital
              </h3>
              <p className="text-[14px] leading-[28px] mb-[15px]">
                Equity capital can be a valuable resource for your businesses in
                that new growth initiatives. At our finance and advisory firm,
                we offer customized solutions to help you raise equity capital
                either in the primary or secondary markets, without incurring
                debt or sacrificing control.
              </p>
              <p className="text-[14px] leading-[28px] mb-[15px]">
                With our wealth of experience working with a wide range of
                businesses, from start-ups to established enterprises, we
                understand the unique challenges and opportunities that
                businesses face in raising equity capital. We'll work closely
                with you to craft a tailored approach that fits your equity
                needs, helping you secure the capital you need to take your
                business to the next level.
              </p>
              <p className="text-[14px] leading-[28px] mb-[15px]">
                WWe believe that every business has its unique challenges and
                opportunities, and our team is dedicated to collaborating with
                our clients providing them with exceptional investor access and
                advice to help them navigate the nuances around the
                transactions. Whether you are seeking to fund a new project,
                expand your operations, or acquire new assets, we will work with
                you to develop a strategy that aligns with your objectives and
                helps you achieve success.
              </p>
            </div>

            <div className="services-img w-full sm:w-[45%]">
              <img src={Equity} alt="" className="block w-full" />
            </div>
          </div>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] sm:px-[4em] py-[2em] overflow-hidden">
        <div className="services-flex flex-col sm:flex-row flex justify-between items-center gap-[3em] w-[90%] mt-[5em] mx-auto ">
          <div className="services-img w-full sm:w-[45%]">
            <img src={Advisory} alt="" className="block w-full" />
          </div>

          <div className="services-text w-full sm:w-[45%]">
            <h3 className="text-[28px] leading-[36px] mb-[0.5em]">
              Financial Advisory
            </h3>
            <p className="text-[14px] leading-[28px] mb-[15px]">
              Our financial advisory service provides specialised professional
              advice to help clients make informed decisions about strategic
              transactions.
            </p>
            <p className="text-[14px] leading-[28px] mb-[15px]">
              One of our success stories is our partnership with Hoop Telecoms
              in raising a whopping US$17.5 million for business expansion and
              working capital financing. Our in-depth industry experience and
              extensive market knowledge enabled us to identify the most
              suitable funding options for Hoop Telecoms and facilitated the
              successful closing of the transaction.
            </p>
            <p className="text-[14px] leading-[28px] mb-[15px]">
              We take pride in being the financial advisors for numerous firms
              that have successfully raised equity and debt funding to meet
              their capital needs in the African market. Our success is
              attributed to our team who are skilled in financial analysis, risk
              management, and strategic planning.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#5DDDFF]">
        <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] sm:px-[4em] py-[2em] overflow-hidden">
          <div className="services-flex flex-col-reverse sm:flex-row flex justify-between items-center gap-[3em] w-[90%] mt-[5em] mx-auto ">
            <div className="services-text w-full sm:w-[45%]">
              <h3 className="text-[28px] leading-[36px] mb-[0.5em]">
                Project Finance
              </h3>
              <p className="text-[14px] leading-[28px] mb-[15px]">
                As a forward-thinking firm, we understand that securing funding
                for ambitious projects can be challenging, especially in
                emerging markets. We stay up to date on the latest changes in
                the local project finance landscape to create transaction
                structures that reduce inherent risks.
              </p>
              <p className="text-[14px] leading-[28px] mb-[15px]">
                Over the years, we have successfully helped businesses across
                various industries secure financing for their project
                initiatives using a project finance note. Our flexible terms and
                competitive rates make it easier for businesses to access the
                funding they need to take on new long-term ventures or expand
                existing ones.
              </p>
              <p className="text-[14px] leading-[28px] mb-[15px]">
                If you are a business owner seeking to fund ambitious projects,
                Sthenic’s project finance service may be a powerful tool for
                you. We craft strategies that are unique to our clients to help
                them meet their specific project needs while providing guidance
                and support throughout the process.
              </p>
            </div>

            <div className="services-img w-full sm:w-[45%]">
              <img src={Finance} alt="" className="block w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] px-[2em] sm:px-[4em] py-[2em] overflow-hidden">
        <div className="services-flex flex-col sm:flex-row flex justify-between items-center gap-[3em] w-[90%] mt-[5em] mx-auto ">
          <div className="services-img w-full sm:w-[45%]">
            <img src={Broker} alt="" className="block w-full" />
          </div>

          <div className="services-text w-full sm:w-[45%]">
            <h3 className="text-[28px] leading-[36px] mb-[0.5em]">
              Brokerage Services
            </h3>
            <p className="text-[14px] leading-[28px] mb-[15px]">
              Intermediation just got easier with our service.{" "}
            </p>
            <p className="text-[14px] leading-[28px] mb-[15px]">
              Our finance and advisory company offer innovative and dynamic
              brokerage services designed to help you achieve your investment
              goals. Our expert brokers bring a wealth of experience and
              knowledge to the table, providing our clients with expert
              negotiation skills, and making sure we secure the best deal
              possible.
            </p>
            <p className="text-[14px] leading-[28px] mb-[15px]">
              Looking to invest in stocks, bonds, or alternative investments? We
              have the expertise and resources to help our clients succeed. With
              our cutting-edge technology and market insights, we provide
              clients with the latest information to make informed investment
              decisions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
