import React from "react";
import Scroll from "../../Reusables/Animation/Scroll";
const Purchase = () => {
  const test = JSON.parse(localStorage.getItem("imgs"));

  return (
    <div>
      <div className="relative">
        <div className="h-[60vh] relative top-[65px] sm:h-screen w-full  bg-[black]">
          <img
            src={test.Img177}
            alt=""
            className=" h-full opacity-70 w-full  object-cover object-center"
          />
        </div>
        <div className="flex justify-center flex-col py-[2em] sm:py-[4em] pl-[2em] sm:pl-[10em] absolute top-[5%] text-[white] w-[90%] sm:w-[80%] 2xl:w-full mt-2">
          <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] ">
            <h2 className="text-[25px] md:text-[35px] lg:text-[60px]">
              Purchase Order Financing Note
            </h2>
          </div>
        </div>
      </div>

      <div className="mr-0 ml-0  2xl:ml-auto 2xl:mr-auto 2xl:w-[1200px] career-container py-[2em] px-[2em] sm:px-[4em] overflow-hidden">
        <Scroll
          animationDirection={"left"}
          possibleClass="career-header text-[#001D45] mt-[2em] pb-[30px]"
        >
          <h2 className="text-[40px] leading-[48px] ">
            Purchase Order Financing Note
          </h2>
          <p className="text-[16px] leading-[24px] pb-[30px]">
            This financing option can be structured as a line of credit secured
            by the company’s active Purchase Orders (PO).
          </p>
          <p>
            {" "}
            It is available as fallback to businesses with low cash flow reserve
            due to long receivable days, for the execution of the PO.
          </p>
        </Scroll>

        <Scroll
          animationDirection={"right"}
          possibleClass="product-main relative w-full h-full"
        >
          <table className="w-full border-collapse">
            <tr>
              <th>SECTOR</th>
              <th>Agnostic, B2B; B2C</th>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                CLUSTER OF INTEREST
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                Facility Management, Food Production/Manufacturers, Import
                /Export, Retail, Agro Business
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                TRANSACTION SIZE
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                5-20 million Naira
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                PRICING
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                Min. 5% IRR
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                TENOR
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                90days per cycle
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                PROCESSING TIME
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                7-14 days
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                REPAYMENT SOURCE
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                <li>Operational Cashflow</li>
                <li>Revenue from financed PO</li>
              </td>
            </tr>
            <tr>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                REQUIREMENTS
              </td>
              <td className="p-[8px] border-solid border-[1px] border-[#ddd]">
                <li>Competitive receivable days</li>
                <li>High credit ratings of customers</li>
                <li>Strong execution capacity</li>
                <li>Execution/procurement cost schedule</li>
                <li>Selfcollate ralization of financed PO</li>
                <li>Min.35% retention rate on the LPO</li>
                <li>
                  Enhancement
                  <p className="ml-[20px]">1. Third party guarantee</p>
                  <p className="ml-[20px]">2. Additional collateral</p>
                </li>
              </td>
            </tr>
          </table>
        </Scroll>
      </div>
    </div>
  );
};

export default Purchase;
