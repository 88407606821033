import React,{useEffect} from 'react'
import MainHeader from '../Reusables/Header/MainHeader'
import Footer from '../Reusables/Footer/Footer'
import Contact1 from './components/Contact1'
const ContactUs1 = () => {
  useEffect(() => {
    document.title = "Contact Us";
  }, []);
  return (
    <div>
        <MainHeader/>
        <Contact1/>
        <Footer/>
    </div>
  )
}

export default ContactUs1