import React,{useEffect} from 'react'
import SCMHeader from '../Reusables/Header/SCMHeader'
import Export from './components/Export'
import Footer from '../Reusables/Footer/Footer'
const ExportFinance = () => {
  useEffect(() => {
    document.title = "Export Finance";
  }, []);
  return (
    <div>
        <SCMHeader/>
        <Export/>
        <Footer/>
        </div>
  )
}

export default ExportFinance