import React,{useEffect} from 'react'
import Potential from './components/Potential'
import SFAHeader from '../Reusables/Header/SFAHeader'
import Footer from '../Reusables/Footer/Footer'
const Potentials = () => {
  useEffect(() => {
    document.title = "Potentials";
  }, []);
  return (
    <div>
        <SFAHeader/>
        <Potential/>
        <Footer/>
        </div>
  )
}

export default Potentials